import { Col, Divider, Row } from "antd";
import leadership_anand from "../../assets/images/leadership/anand_venugopal.png";
import leadership_ankita from "../../assets/images/leadership/leadership_ankita.png";
import leadership_ashish from "../../assets/images/leadership/leadership_ashish.png";
import leadership_deepti from "../../assets/images/leadership/leadership_deepti.png";
import leadership_kiran from "../../assets/images/leadership/leadership_kiran.png";
import leadership_kristen from "../../assets/images/leadership/leadership_kristen.png";
import leadership_manjunath from "../../assets/images/leadership/leadership_manjunath.png";
import leadership_swapnil from "../../assets/images/leadership/leadership_swapnil.png";
import leadership_will from "../../assets/images/leadership/leadership_will.png";
import leadership_vishesh from "../../assets/images/leadership/vishesh_tungare.png";
import "./leadershipComponent.scss";

import CirleImage from "../../Components/CircleImage/CircleImage";
import LeadershipIntroLeft from "../../Components/LeadershipIntroLeft/LeadershipIntroLeft";
// import LeadershipIntroRight from "../../Components/LeadershipIntroRight/LeadershipIntroRight";
import leadershipIntro from "../../mocks/leadershipIntro";

const leadershipData = [
  {
    name: "ASHISH BAGHEL",
    image: leadership_ashish,
    designation: "Founder & CEO",
    about: leadershipIntro.ashishIntro,
    linkding: "https://www.linkedin.com/in/ashish-b-baghel/",
    mail: "ashish@nucleusteq.com",
  },
  {
    name: "WILL SELLENRAAD",
    image: leadership_will,
    designation: "EVP, CRO",
    about: leadershipIntro.willIntro,
    linkding: "https://www.linkedin.com/in/willsellenraad/",
    mail: "will@nucleusteq.com",
  },
  {
    name: "KIRAN RAMIREDDI",
    image: leadership_kiran,
    designation: "Executive Vice President",
    about: leadershipIntro.kiranIntro,
    linkding: "https://www.linkedin.com/in/kiranramireddi/",
    mail: "kiran@nucleusteq.com",
  },
  {
    name: "KRISTEN KALUPSKI",
    image: leadership_kristen,
    designation: "CMO",
    about: leadershipIntro.kristenIntro,
    linkding: "https://www.linkedin.com/in/kristenkalupski/",
    mail: "kristen@nucleusteq.com",
  },
  {
    name: "ANAND VENUGOPAL",
    image: leadership_anand,
    designation: "Vice President, Global Product Strategy & Go-to-Market",
    about: leadershipIntro.anandIntro,
    linkding: "https://www.linkedin.com/in/anandvenugopal/",
    mail: "anand@nucleusteq.com",
  },
  {
    name: "DEEPTI BAGHEL",
    image: leadership_deepti,
    designation: "Vice President",
    about: leadershipIntro.deeptiIntro,
    linkding: "https://www.linkedin.com/in/deepti-baghel/",
    mail: "deepti@nucleusteq.com",
  },
  {
    name: "SWAPNIL DESHMUKH",
    image: leadership_swapnil,
    designation: "Senior Director of Information & Cloud Technology",
    about: leadershipIntro.swapnilIntro,
    linkding: "https://www.linkedin.com/in/connect-swapnil/",
    mail: "swapnil.deshmukh@nucleusteq.com",
  },
  {
    name: "MANJUNATH SIDDAIAH",
    image: leadership_manjunath,
    designation: "Senior Director – Strategic Accounts",
    about: leadershipIntro.majunathIntro,
    linkding: "https://www.linkedin.com/in/manjunath-siddaiah-169a669/",
    mail: "manjunath@nucleusteq.com",
  },
  {
    name: "ANKITA SHARMA",
    image: leadership_ankita,
    designation: "Director - Human Empowerment",
    about: leadershipIntro.ankitaIntro,
    linkding: "https://www.linkedin.com/in/ankita-s-sharma/",
    mail: "ankita@nucleusteq.com",
  },
  {
    name: "VISHESH TUNGARE",
    image: leadership_vishesh,
    designation: "Head Of Products Development",
    about: leadershipIntro.visheshIntro,
    linkding: "https://linkedin.com/in/visheshtungare/",
    mail: "vishesh.tungare@nucleusteq.com",
  },
  
];

const LeadershipComponent = (props: any) => {
  return (
    <>
      <div className="leadershipcomp_dekstopview">
        {leadershipData.map((leader: any, index: number) => {
          const isLastElement = index === leadershipData.length - 1;
          return (
            <>
              <Row justify="center" style={{ paddingBottom: "0vw" }}>
                <Col xs={24} sm={10} lg={8} xxl={8} xl={7} md={8}>
                  <CirleImage
                    img={leader.image}
                    routeLinkedin={leader.linkding}
                    routeMail={leader.mail}
                  />
                </Col>
                <Col
                  xs={24}
                  sm={14}
                  lg={16}
                  xxl={16}
                  xl={17}
                  md={16}
                  style={{ textAlign: "justify" }}
                >
                  <LeadershipIntroLeft
                    name={leader.name}
                    post={leader.designation}
                    about={leader.about}
                  />
                </Col>
              </Row>
              {!isLastElement && (
                <Divider
                  style={{
                    borderTop: "1px solid rgb(255,255,255,.1)",
                    paddingBottom: "2.5vw",
                  }}
                />
              )}
            </>
          );
        })}
      </div>

      {/*Mobile view*/}

      <div className="leadershipcomp_mobileview">
        {leadershipData.map((leader: any, index: number) => {
          const isLastElement = index === leadershipData.length - 1;
          return (
            <>
              <Row justify="center">
                <Col xs={24} sm={10}>
                  <CirleImage
                    img={leader.image}
                    routeLinkedin={leader.linkding}
                    routeMail={leader.mail}
                  />
                </Col>
                <Col xs={24} sm={10}>
                  <LeadershipIntroLeft
                    name={leader.name}
                    post={leader.designation}
                    about={leader.about}
                  />
                </Col>
              </Row>
              {!isLastElement && (
                <Divider
                  style={{
                    borderTop: "1px solid  rgb(255,255,255,.1)",
                    paddingBottom: "2.5vw",
                  }}
                />
              )}
            </>
          );
        })}
      </div>
    </>
  );
};
export default LeadershipComponent;
