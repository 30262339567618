import ContentOfPages from "../../Components/ContentOfPages/ContentOfPages";
import GlobalPresences from "../../Components/GlobalPresences/GlobalPresences";
import { HeroIndustries } from "../../Components/HeroIndustries";
import call from "../../assets/images/contactUs/call.webp";
import location from "../../assets/images/contactUs/location.webp";
import sms from "../../assets/images/contactUs/sms.webp";
import contactUs_background from "../../assets/images/contactUs/contactUs_background.webp";
// +1 480-805-8441
const Contact = (props: any) => {
  const map = [
    {
      width: "100%",
      height: "200",
      src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.601513372264!2d-111.98474942476805!3d33.64157283930404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b711fda274e49%3A0x8f91e1b30d39ad9e!2sNucleusTeq!5e0!3m2!1sen!2sin!4v1700150965097!5m2!1sen!2sin",
      country: "US HEADQUARTERS",
      state: "PHOENIX - Arizona",
      image1: call,

      phoneNumber: "+1 480-805-8441",
      phoneNumberCall: "+14808058441",

      image2: sms,
      mail: "ops@nucleusteq.com",
      image3: location,

      location: "4550 E Bell Rd Building 3, Suite 222",
    },
    {
      width: "100%",
      height: "200",
      src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2610.8831476304963!2d-123.09985872382133!3d49.12685438177671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485e0723cb440ff%3A0xdaceae738cb1bf1!2s11871%20Horseshoe%20Way%20%231103%2C%20Richmond%2C%20BC%20V7A%205H5%2C%20Canada!5e0!3m2!1sen!2sus!4v1700198754190!5m2!1sen!2sus",
      country: "CANADA",
      state: "VANCOUVER/RICHMOND – BRITISH COLUMBIA",
      image1: call,

      phoneNumber: "+1 480-805-8441",
      phoneNumberCall: "+14808058441",
      image2: sms,

      mail: "ops@nucleusteq.com",
      image3: location,

      location: " 1103 - 11871 Horseshoe Way Richmond BC V7A 5H5 Canada",
    },
    {
      width: "100%",
      height: "200",
      src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4966.119459652454!2d-0.13372616657090786!3d51.51212017369443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604cb878e81b3%3A0x91f10fa364452046!2sCovent%20Garden%2C%20London%2C%20UK!5e0!3m2!1sen!2sus!4v1701795287053!5m2!1sen!2sus",
      country: "UK",
      state: "UNITED KINGDOM",
      image1: call,

      phoneNumber: "+1 480-805-8441",
      phoneNumberCall: "+14808058441",
      image2: sms,

      mail: "ops@nucleusteq.com",
      image3: location,

      location:
        "NucleusTeq UK LTD, 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ",
    },
  ];

  const data = [
    {
      width: "100%",
      height: "200",
      src: "https://maps.google.com/maps?q=nucluesTeq%20Raipur&t=&z=13&ie=UTF8&iwloc=&output=embed",
      country: "RAIPUR-CHHATTISGARH",
      state: "",
      image1: call,

      phoneNumber: "+91 903-962-4812",
      phoneNumberCall: "+919039624812",
      image2: sms,

      mail: "ops@nucleusteq.com",
      image3: location,

      location: "C-97 Samta Colony, 492001",
    },

    {
      width: "100%",
      height: "200",
      src: "https://maps.google.com/maps?q=nucleusteq%20Indore&t=&z=13&ie=UTF8&iwloc=&output=embed ",
      country: "INDORE-MADHYA PRADESH",
      state: "",
      image1: call,

      phoneNumber: "+91 903-962-4812",
      phoneNumberCall: "+919039624812",
      image2: sms,

      mail: "ops@nucleusteq.com",
      image3: location,

      location:
        "Brilliant Platina, 4th Floor, Scheme No. 78, Vijay Nagar, 452010",
    },
  ];

  return (
    <div style={{ backgroundColor: " #1D1A36" }}>
      <HeroIndustries
        pageName="CONTACT US"
        pageIntro="JOIN OUR JOURNEY OF EMPOWERING & TRANSFORMING BUSINESSES"
        pageIntroPara={"Where Data Innovation Meets Digital Transformation."}
        alt="img"
        image={contactUs_background}
      />
      <ContentOfPages
        text="NucleusTeq is a software services, solutions & products company empowering & transforming customers’ 
businesses through digital technologies such as big data, Analytics (AI, ML), Cloud, Enterprise Automation, Blockchain, Mobility, CRM & ERP.
INTRODUCING OUR PRODUCTS."
      />
      <GlobalPresences maps={map} text="OUR GLOBAL PRESENCE" />
      <GlobalPresences maps={data} text="INDIA" />
    </div>
  );
};

export default Contact;
