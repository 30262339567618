import React from "react";
import "./success.scss";
import { Col, Row } from "antd";
import { CommonButton } from "../CommonButton";
import { FAQButton } from "../FAQButton";
import { BsArrowRight } from "react-icons/bs";

interface Props {
  successText: string;
  succesImage1: string;
  link: string;
}

const Success = ({ successText, succesImage1, link }: Props) => {
  return (
    <Row className="successMain">
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={16}
        xxl={16}
        className="successMain"
      >
        <div className="successContainer">
          <div className="success-content content">
            <div className="content-overlay"></div>
            <img className="content-image" src={succesImage1} alt="" />
            <div className="content-details fadeIn-bottom">
              <Row
                justify={"center"}
                align={"middle"}
                style={{ width: "100%" }}
              >
                <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                  <h3 className="content-title">{successText}</h3>
                  {/* <p className="content-text"> */}
                    <CommonButton
                      buttonText="Read More"
                      link={link}
                      icon={<BsArrowRight />}
                    />
                  {/* </p> */}
                </Col>

                <Col
                  xs={0}
                  sm={0}
                  md={24}
                  lg={8}
                  xl={8}
                  xxl={8}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  className="successDesktop"
                >
                  <FAQButton />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Col>

      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={0}
        xxl={0}
        className="successMain successMob"
      >
        <FAQButton />
      </Col>
    </Row>
  );
};

export default Success;
