"use client";

import { Image } from "antd";
import React from "react";
import Anish_Jacob from "../../assets/events_images/speakers/Anish_Jacob.webp";
import Ashish_Baghel from "../../assets/events_images/speakers/Ashish_Baghel.webp";
import Beth_McCormick from "../../assets/events_images/speakers/Beth-McCormick.webp";
import Danielle_Crop from "../../assets/events_images/speakers/Danielle_Crop.webp";
import Deepti_Baghel from "../../assets/events_images/speakers/Deepti_Baghel.webp";
import Kevin_Clugage from "../../assets/events_images/speakers/Kevin_Clugage.webp";
import Kiran_Ramireddi from "../../assets/events_images/speakers/Kiran_Ramireddi.webp";
import Kristen_Kalupski from "../../assets/events_images/speakers/Kristen_Kalupski.webp";
import Luis_Gonzalez from "../../assets/events_images/speakers/Luis_Gonzalez.webp";
import Matt_McDonald from "../../assets/events_images/speakers/Matt_McDonald.webp";
import Phani_Kalyan_Cherukuri from "../../assets/events_images/speakers/Phani_Kalyan_Cherukuri.webp";
import Sanjay_Gwalani from "../../assets/events_images/speakers/Sanjay_Gwalani.webp";
import Shankar_Vedaraman from "../../assets/events_images/speakers/Shankar_Vedaraman.webp";
import Srini_Nanduri from "../../assets/events_images/speakers/Srini_Nanduri.webp";
import Marela_Vairo from "../../assets/events_images/speakers/Marela_Vairo.webp";
import Anand_Venugopal from "../../assets/events_images/speakers/Anand_Venugopal.webp";
import Justine_Grieshop from "../../assets/events_images/speakers/Justine_Grieshop.webp";
import Brian_Quinn from "../../assets/events_images/speakers/Brian_Quinn.webp";
import Naveen_Chhajer from "../../assets/events_images/speakers/Naveen_Chhajer.webp";
import Swagata_Ghosh from "../../assets/events_images/speakers/Swagata_Ghosh.webp";
import Bharath_Venugopal from "../../assets/events_images/speakers/Bharath_Venugopal.webp";
interface SpeakersMember {
  image: string;
  name: string;
  designation: string;
  linkedinUrl?: string;
  id?: number;
  isPlaceholder: boolean;
}

const SpeakersMemberData: SpeakersMember[] = [
  {
    image: Phani_Kalyan_Cherukuri,
    name: "Phani Kalyan Cherukuri",
    designation: "American Express - Vice President, Engineering",
    linkedinUrl: "https://www.linkedin.com/in/phani-kalyan-cherukuri-81057196/",
    isPlaceholder: false,
  },
  {
    image: Beth_McCormick,
    name: "Beth McCormick",
    designation: "CIO, Cava - Executive Leadership Board, Retail AI Council",
    linkedinUrl: "https://www.linkedin.com/in/beth-starling-mccormick-8970b84/",
    isPlaceholder: false,
  },
  {
    image: Anish_Jacob,
    name: "Anish Jacob",
    designation: "Jenius Bank - Managing Director & CIO",
    linkedinUrl: "https://www.linkedin.com/in/anish-jacob-6174449/",
    isPlaceholder: false,
  },
  {
    image: Shankar_Vedaraman,
    name: "Shankar Vedaraman",
    designation: "Salesforce - Vice President, Data and Analytics",
    linkedinUrl: "https://www.linkedin.com/in/shankar-vedaraman-1293281/",
    isPlaceholder: false,
  },
  {
    image: Sanjay_Gwalani,
    name: "Sanjay Gwalani",
    designation:
      "American Express - Vice President, Risk Product & Platform Development",
    linkedinUrl: "https://www.linkedin.com/in/sanjay-gwalani-2b34225/",
    isPlaceholder: false,
  },
  {
    image: Ashish_Baghel,
    name: "Ashish Baghel",
    designation: "NucleusTeq & NuoData - Founder and CEO",
    linkedinUrl: "https://www.linkedin.com/in/ashish-b-baghel/",
    isPlaceholder: false,
  },
  {
    image: Swagata_Ghosh,
    name: "Swagata Ghosh",
    designation: "American Express - Vice President of Engineering",
    linkedinUrl: "https://www.linkedin.com/in/swagata-ghosh-57a6759/",
    isPlaceholder: false,
  },
  {
    image: Srini_Nanduri,
    name: "Srini Nanduri",
    designation:
      "Republic Services - VP, Head of Data Science AI/ML and Enterprise Analytics",
    linkedinUrl: "https://www.linkedin.com/in/srini-nanduri/",
    isPlaceholder: false,
  },
  {
    image: Luis_Gonzalez,
    name: "Luis Gonzalez",
    designation:
      "PetSmart - Senior Director, Data Engineering and Architecture",
    linkedinUrl: "https://www.linkedin.com/in/luiscgonzalez/",
    isPlaceholder: false,
  },
  {
    image: Matt_McDonald,
    name: "Matt McDonald",
    designation:
      "CAVA- Senior Director - Data Engineering, Data Platforms, and Analytics",
    linkedinUrl: "https://www.linkedin.com/in/matt-mcdonald-833b9954/",
    isPlaceholder: false,
  },
  {
    image: Naveen_Chhajer,
    name: "Naveen Chhajer",
    designation: "Salesforce - Senior Director, Data Management",
    linkedinUrl: "",
    isPlaceholder: false,
  },
  {
    image: Marela_Vairo,
    name: "Marcela Vairo",
    designation: "IBM - VP, Data & AI, Americas",
    linkedinUrl: "https://www.linkedin.com/in/marcela-vairo/",
    isPlaceholder: false,
  },
  {
    image: Bharath_Venugopal,
    name: "Bharath Venugopal",
    designation: "Adobe - Head of B2B Analytics & Data Science",
    linkedinUrl: "https://www.linkedin.com/in/bharathvenugopal/",
    isPlaceholder: false,
  },
  {
    image: Brian_Quinn,
    name: "Brian Quinn",
    designation: "CBTS - Senior Vice President, AI Product Development",
    linkedinUrl: "https://www.linkedin.com/in/brian-quinn-05a68736/",
    isPlaceholder: false,
  },
  {
    image: Kevin_Clugage,
    name: "Kevin Clugage",
    designation: "Databricks - Principal Product Marketing Manager",
    linkedinUrl: "https://www.linkedin.com/in/kevin-clugage/",
    isPlaceholder: false,
  },
  {
    image: Kiran_Ramireddi,
    name: "Kiran Ramireddi",
    designation: "NucleusTeq - EVP, Strategy",
    linkedinUrl: "https://www.linkedin.com/in/kiranramireddi/",
    isPlaceholder: false,
  },
  {
    image: Kristen_Kalupski,
    name: "Kristen Kalupski",
    designation: "NucleusTeq - CMO",
    linkedinUrl: "https://www.linkedin.com/in/kristenkalupski/",
    isPlaceholder: false,
  },
  {
    image: Anand_Venugopal,
    name: "Anand Venugopal",
    designation:
      "NucleusTeq & NuoData - VP, Global Product Strategy & Go-to-Market",
    linkedinUrl: "https://www.linkedin.com/in/anandvenugopal/",
    isPlaceholder: false,
  },
  {
    image: Deepti_Baghel,
    name: "Deepti Baghel",
    designation: "NuoData - VP, Technology",
    linkedinUrl: "https://www.linkedin.com/in/deepti-baghel/ ",
    isPlaceholder: false,
  },
];

const Speakers: React.FC = () => {
  return (
    <>
      <div className="speakers-area  pb-0">
        <div className="speakers-section">
          <div className="container">
            <div className="speakers-section-title">
              <span>Listen to the keynote speakers</span>
              <h2>Who&apos;s Speaking</h2>
            </div>
          </div>
          <div className="bg-title">Speakers</div>
        </div>

        <div className="speakers-row row m-0">
          {SpeakersMemberData &&
            SpeakersMemberData.map((value, i) => (
              <div className="col-lg-3 col-sm-6 p-0" key={i}>
                <div className="elkevent-single-speakers">
                  <>
                    <a
                      href={value.linkedinUrl ? value.linkedinUrl : ""}
                      target={value.linkedinUrl ? "_blank" : ""}
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <Image
                        src={value.image}
                        preview={false}
                        alt="speaker"
                        width={"100%"}
                        height={"100%"}
                      />
                    </a>
                    <div className="speakers-content">
                      <h3>{value.name}</h3>
                      <span>{value.designation}</span>
                    </div>
                  </>
                </div>
              </div>
            ))}
          {/* {SpeakersMemberData.length % 4 == 0 && (
            <div className="col-lg-12 col-sm-12 p-0" key={"comingsoon"}>
              <div className="elkevent-single-speakers">
                <div className="more-speakers-content">
                  <h3>
                    More speakers
                    <br />
                    <span>coming soon!</span>
                  </h3>
                </div>
              </div>
            </div>
          )}
          {SpeakersMemberData.length % 4 == 1 && (
            <div className="col-lg-9 col-sm-6 p-0" key={"comingsoon"}>
              <div className="elkevent-single-speakers">
                <div className="more-speakers-content">
                  <h3>
                    More speakers
                    <br />
                    <span>coming soon!</span>
                  </h3>
                </div>
              </div>
            </div>
          )}
          {SpeakersMemberData.length % 4 == 2 && (
            <div className="col-lg-6 col-sm-12 p-0" key={"comingsoon"}>
              <div className="elkevent-single-speakers">
                <div className="more-speakers-content">
                  <h3>
                    More speakers
                    <br />
                    <span>coming soon!</span>
                  </h3>
                </div>
              </div>
            </div>
          )}
          {SpeakersMemberData.length % 4 == 3 && (
            <div className="col-lg-3 col-sm-6 p-0" key={"comingsoon"}>
              <div className="elkevent-single-speakers">
                <div className="more-speakers-content">
                  <h3>
                    More speakers
                    <br />
                    <span>coming soon!</span>
                  </h3>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default Speakers;
