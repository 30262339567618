import React from "react";
import Hero from "../../Components/HeroIndustries/HeroIndustries";
import { InfoBankingServices } from "../../Components/InfoBankingServices";
import { CommunicationHeading } from "../../Components/CommunicationHeading";
import { CommunicationCard } from "../../Components/CommunicationCard";
import { Accomplish } from "../../Components/Accomplishment";
import OurSuccessStory from "../../Components/OurSuccessStory/OurSuccessStory";

import bgImg5 from "../../assets/images/communication/COMMUNICATION.webp";
// import squarImg from "../../assets/images/acoomplish_component/accomplishImg.jpg";
import img1 from "../../assets/images/communication/communication_crdImg1.webp";
import img2 from "../../assets/images/communication/communication_crdImg2.webp";
import img3 from "../../assets/images/communication/communication_crdImg3.webp";
import commImg from "../../assets/images/communication/communication_succesImg.webp";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import { NewText } from "../../Components/NewText";
import { OrangeBar } from "../../Components/OrangeBar";
const dataCom = [
  {
    comCardImg1: img1,
    linkAnchor: "",
    comCardText1: "Consumer Product Development",
    CardText1:
      "In a fiercely competitive consumer tech landscape, we help you navigate growth and innovation to stand out in the market.",
  },
  {
    comCardImg1: img2,
    linkAnchor: "",
    comCardText1: "Software and Platforms",
    CardText1:
      "As the platform economy thrives, we assist clients in identifying and capitalizing on core opportunities, driving sustained disruption and growth.",
  },
  {
    comCardImg1: img3,
    linkAnchor: "",
    comCardText1: "Communications Technology",
    CardText1:
      " Tailored solutions that leverage the power of modern communication technologies to connect, engage, and transform businesses and industries.",
  },
];

function Communication() {
  FunctionTitles("NucleusTeq | Communication, Media & Technology");
  return (
    <>
      <Hero
        pageName="INDUSTRIES  /  COMMUNICATION, MEDIA& TECHNOLOGY"
        pageIntro="POWERING THE FUTURE OF COMMUNICATION, MEDIA & TECHNOLOGY"
        pageIntroPara={"Connect, Create, and Transform"}
        alt="img"
        image={bgImg5}
      />

      <InfoBankingServices
        heading="COMMUNICATION, MEDIA & TECHNOLOGY"
        alt="Image"
        para1="Innovations in media and technology are revolutionizing the industry through new products, subscription services, and cloud-based models. At NucleusTeq, we address all aspects of these changes, from managing expectations and concerns to developing and executing effective strategies and plans."
        para2=""
        para3=""
      />
      <CommunicationHeading cardHeadingText="OUR  CAPABILITIES" />

      <CommunicationCard servicedata={dataCom} />
      <NewText newtext="NucleusTeq is your partner in shaping the future of Communication, Media & Technology, harnessing the latest innovations to empower your success." />

      {/* <Accomplish
        squareImg={squarImg}
        accomplishText="OUR"
        accomplishText2=" ACCOMPLISHMENTS"
        accomplishText_fortune="Fortune 500 Clients"
      /> */}
      <OrangeBar />
      <OurSuccessStory
        successImg1={commImg}
        successHeading="OUR SUCCESS STORIES"
        successText="Realtime anomaly detection & network monitoring for major telecommunications company in USA."
      />
    </>
  );
}

export default Communication;
