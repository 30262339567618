import React from "react";
import "./ui.scss";
import { Row, Col } from "antd";
import { RightText } from "../../Components/RightText";
import UIUX from "../../assets/images/ui/ui_right_img.webp";
import OrangeBar from "../../Components/OrangeBar/OrangeBar";
import SuccessIndex from "../../Components/Success/SuccessIndex";
import UIBanner from "../../assets/images/services_banners/services_ui.webp";
import uiIcon from "../../assets/images/ui/ui_icon1.webp";
import uiIcon2 from "../../assets/images/ui/ui_icon2.webp";
import uiIcon3 from "../../assets/images/ui/ui_icon3.webp"
import uiIcon4 from "../../assets/images/ui/ui_icon4.webp";
import uiIcon5 from "../../assets/images/ui/ui_icon5.webp";
import uiIcon6 from "../../assets/images/ui/ui_icon6.webp";
import uiIcon7 from "../../assets/images/ui/ui_icon7.webp";
import uiIcon8 from "../../assets/images/ui/ui_icon8.webp";
import uiPartner1 from "../../assets/images/ui/ui_partner1.webp";
import uiPartner2 from "../../assets/images/ui/ui_partner2.webp";
import uiPartner3 from "../../assets/images/ui/ui_partner3.webp";
import uiPartner4 from "../../assets/images/ui/ui_partner4.webp";
import uiPartner5 from "../../assets/images/ui/ui_partner5.webp";
import Partners from "../../Components/Partners/Partners";
import { HeroIndustries } from "../../Components/HeroIndustries";
import { GradientCard } from "../../Components/GradientCard";

const UI = (props: any) => {
  const partnerImages = [
    uiPartner1,
    uiPartner2,
    uiPartner3,
    uiPartner4,
    uiPartner5,
  ];

  const rightTextItems = [
    {
      text: "At NucleusTeq, we are creating unique customer relationship management (CRM) solutions that bring companies and their customers together. Our integrated CRM platform gives all departments including marketing, sales, operations, commerce, and service — a single, shared view of every customer.",
      className: "paragraphText",
    },
    {
      text: "Our ERP solutions are enabling business’s manage organization to optimize accounting, inventory control, payroll and human resources by automating many back-office functions",
      className: "paragraphText",
    },
  ];

  const cardDataSets = [
    {
      image: uiIcon,
      heading: "Sales",
      lgValue: 5,
      text: "Leave no sale behind by automating workflows from lead to revenue. Help your sales reps sell more effectively by working together.",
    },
    {
      image: uiIcon2,
      heading: "Marketing Automation",
      lgValue: 5,
      text: "Fill your pipeline faster by teaming up sales with marketing. Enable high-quality leads by combining marketing automation with sales intelligence.",
    },
    {
      image: uiIcon3,
      heading: "Integration & Services",
      lgValue: 5,
      text: "We deliver instant, personalized services that drive growth and exceed customer expectations.",
    },
    {
      image: uiIcon4,
      heading: "Technology Advisory & Engineering",
      lgValue: 5,
      text: "We create true digital experience by leveraging Cloud, Analytics and Data, while securing your assets.",
    },
  ];
  const cardDataSets2 = [
    {
      image: uiIcon5,
      heading: "ERP and Finance",
      lgValue: 5,
      text: "Leave no sale behind by automating workflows from lead to revenue. Help your sales reps sell more effectively by working together.",
    },
    {
      image: uiIcon6,
      heading: "Network and Spend Managements",
      lgValue: 5,
      text: "Fill your pipeline faster by teaming up sales with marketing, Enable high-quality leads that convert by combining marketing automation with sales intelligence.",
    },
    {
      image: uiIcon7,
      heading: "Digital Supply Chain",
      lgValue: 5,
      text: "We deliver instant, personalized service that drives growth and exceeds customer’s expectations.",
    },
    {
      image: uiIcon8,
      heading: "HR and People Engagement",
      lgValue: 5,
      text: "We create true digital experience by leveraging Cloud, Analytics and Data, while securing your assets.",
    },
  ];

  return (
    <>
      <div className="uiMain">
        <HeroIndustries
          image={UIBanner}
          alt={"edmBanner"}
          pageName={"SERVICES  /  CRM"}
          pageIntro={"CRM AND ERP SERVICES"}
          pageIntroPara={"Integrate to Innovate"}
        />
        <Row justify={"center"} align={"middle"} className="autoMarginTop">
          <Col md={21} xs={22} sm={22} className="uiCenter">
            <p className="paragraphText">
              In the world of business, greatness stems from two core
              principles: building exceptional customer relationships and
              managing resources effectively. At NucleusTeq, we specialize in
              crafting CRM and ERP solutions that transform these principles
              into reality.
            </p>
          </Col>
          <Col md={21} xs={22} sm={22} className="uiCenter">
            <p className="paragraphText">
              Our unique Customer Relationship Management (CRM) solutions are
              designed to unite companies and their customers, creating powerful
              connections. Through our integrated CRM platform, every
              department, from marketing and sales to operations, commerce, and
              service, gains access to a single, shared view of every customer.
            </p>
          </Col>
          <Col md={21} xs={22} sm={22} className="uiCenter">
            <p className="paragraphText">
              With our Enterprise Resource Planning (ERP) solutions, we empower
              enterprise businesses to optimize their accounting, inventory
              control, payroll, and human resources. By automating numerous
              back-office functions, our ERP solutions streamline operations,
              boost efficiency, and drive growth.
            </p>
          </Col>
        </Row>
        {/* <RightText rightTextItems={rightTextItems} rightTextImage={UIUX} /> */}

        <Row className="uiCenter">
          <h3 className="commonTitleHead">
            NUCLEUSTEQ ERP SERVICES
          </h3>
        </Row>
        <GradientCard data={cardDataSets} />

        <Row className="uiCenter">
          <h3 className="commonTitleHead">OUR ERP SERVICES</h3>
        </Row>
        <GradientCard data={cardDataSets2} />

        <Partners
          partnerTitle="OUR AUTOMATION PARTNERS"
          partnerImages={partnerImages}
        />
        <OrangeBar />
        <SuccessIndex />
      </div>
    </>
  );
};

export default UI;
