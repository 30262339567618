import React, { useState } from "react";
import "./mails.scss";
import { PopupModal } from "../PopupModal";
import { Col, Row, Input, Form, Button, Checkbox } from "antd";

const Mails = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false); // Added state for checkbox

  const [formValues, setFormValues] = useState({
    "Email Address": "",
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleButtonClick = () => {
    if (Object.values(formValues).some((value) => value === "")) {
      return;
    }
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  // Handle checkbox change
  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <div className="mail_main_div">
        <Row justify={"center"} align={"middle"}>
          <Col span={14} className="mail_main_div_leftSide1">
            <Form
              name="mails"
              initialValues={{ remember: true }}
              className="mail_main_div_leftFormInput1"
              autoComplete="off"
            >
              <Form.Item
                name="Email Address"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email Address!",
                  },
                ]}
              >
                <Input
                  className="mail_main_div_formInput1"
                  placeholder="Email Address"
                  variant="borderless"
                  name="Email Address"
                  value={formValues["Email Address"]}
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Checkbox className="mail_main_div_checkbox" onChange={handleCheckboxChange}>
                By continuing, you accept the privacy policy
              </Checkbox>
              <Form.Item style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="mail_main_div_btnSubmit1"
                  onClick={handleButtonClick}
                  disabled={!isChecked} // disable the button if checkbox is not checked
                >
                  Subscribe
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
      <PopupModal
        modalVisible={modalVisible}
        closeModal={handleModalClose}
        heading="Submitted!"
        text="We Will Get Back To You Soon."
      />
    </>
  );
};

export default Mails;
