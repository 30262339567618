import React from "react";

import CapitalMarketCards from "../../Components/CapitalMarketCards/CapitalMarketCards";
import Accomplish from "../../Components/Accomplishment/Accomplish";
import OurSuccessStory from "../../Components/OurSuccessStory/OurSuccessStory";

import bgImg5 from "../../assets/images/insurance/INSURANCE.webp";
import squarImg from "../../assets/images/acoomplish_component/accomplishImg.webp";
import commImg from "../../assets/images/insurance/insurence_success_Img.webp";
import insurance_card_Img1 from "../../assets/images/insurance/insurance_card_Img1.webp";
import insurance_card_Img2 from "../../assets/images/insurance/insurance_card_Img2.webp";
import insurance_card_Img3 from "../../assets/images/insurance/insurance_card_Img3.webp";
import insurance_card_Img4 from "../../assets/images/insurance/insurance_card_Img4.webp";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import { HeroIndustries } from "../../Components/HeroIndustries";
import InfoBankingServices from "../../Components/InfoBankingServices/InfoBankingServices";
import { CommunicationHeading } from "../../Components/CommunicationHeading";
import { NewText } from "../../Components/NewText";
import { OrangeBar } from "../../Components/OrangeBar";
const dataTravel = [
  {
    card1: insurance_card_Img1,
    comCardText1: "Property & Casualty Insurance",
    CardText1:
      "Capitalize on the potential of AI, ML, Cloud, and digital tools to enhance P&C operations and drive excellence.",
  },
  {
    card1: insurance_card_Img2,
    comCardText1: "B2B Insurance Brokerage",
    CardText1:
      "Harness cutting-edge technology for B2B underwriting and insurance brokerage services, setting new industry standards.",
  },
  {
    card1: insurance_card_Img3,
    comCardText1: "Life Insurance",
    CardText1:
      "Manage costs, mitigate risks, and fuel growth through refined data science models tailored to the unique needs of life insurers.",
  },
  {
    card1: insurance_card_Img4,
    comCardText1: "Technology Advisory & Engineering",
    CardText1:
      "Transform your enterprise into a true digital powerhouse, leveraging Cloud, Analytics, Big Data, and Blockchain, all while fortifying your security measures to protect your assets.",
  },
];

function Insurance() {
  FunctionTitles("NucleusTeq | Insurance");
  return (
    <>
      <HeroIndustries
        pageName="INDUSTRIES  /  INSURANCE"
        pageIntro="REVOLUTIONIZING INSURANCE IN A DATA-DRIVEN WORLD"
        pageIntroPara="Empowering Insurers to Embrace the Future"
        alt="img"
        image={bgImg5}
      />

      <InfoBankingServices
        heading="INSURANCE "
        alt="Image"
        para1="NucleusTeq's insurance services leverage AI, cloud technology, and other transformative innovations to enhance operations, drive revenue growth, and boost cyber resilience for Property & Casualty insurers, B2B insurers, life carriers, and reinsurers. We empower insurers to become more agile and secure, enabling them to navigate the ever-evolving landscape with confidence."
        para2=""
        para3=""
      />
      <CommunicationHeading cardHeadingText="OUR INSURANCE CAPABILITIES" />
      <CapitalMarketCards dataTravel={dataTravel} />
      <NewText newtext="NucleusTeq is your partner in revolutionizing the insurance industry, equipping insurers with the tools, technology, and strategies needed to thrive in the digital age. Join us in embracing the future of insurance with confidence and innovation."/>
      {/* <Accomplish
        squareImg={squarImg}
        accomplishText="OUR"
        accomplishText2=" ACCOMPLISHMENTS"
        accomplishText_fortune="Fortune 500 Clients"
      /> */}

      <OrangeBar />

      <OurSuccessStory
        successImg1={commImg}
        successHeading="OUR SUCCESS STORIES"
        successText="Enterprise Data modernization of one of world’s largest credit card company from Teradata to On-Premise Big-Data Lake"
      />
    </>
  );
}

export default Insurance;
