import React from "react";
import "./productIntroduction.scss";
import { Col, Row, Image } from "antd";
import fyndr from "../../assets/images/ProductIntroduction/fyndr.webp";
import nuodata from "../../assets/images/ProductIntroduction/nuodata.webp";
import fyndrWeb from "../../assets/images/ProductIntroduction/fyndrWeb.webp";
import nuodataWeb from "../../assets/images/ProductIntroduction/NuodataWeb.webp";
import { CommonButton } from "../CommonButton";
import NewTitle from "../NewTitle/NewTitle";
import { BsArrowRight } from "react-icons/bs";

function ProductInttroduction() {
  return (
    <div className="productIntro_mainContainer">
      <div className="productIntro_productName">
        <NewTitle
          text="INTRODUCING OUR PRODUCTS"
          textTitleClassName="textTitleClassName"
        />
      </div>
      <Row justify={"center"} className="productIntro_intromainRow ">
        <Col xs={24} sm={24} md={24} lg={24} xl={9} xxl={9}>
          <div>
            <Row className="productIntro_fyndrProductImgDiv">
              <Col>
                <Image
                  src={nuodata}
                  preview={false}
                  className="productIntro_nuodataProductImg"
                />
              </Col>
            </Row>

            {/* <Row justify={"center"}>
              <Col className="productIntro_aboutNuoData">Meet NuoData</Col>{" "}
            </Row> */}
            <div className="productIntro_aboutfyndrIntro">
              Revolutionizing Data Modernization and Management with Unmatched
              Speed, Cost Efficiency, and Precision
            </div>

            {/* <div className="productIntro_aboutfyndrIntro1">
              warehouses, ETL, and analytics systems improving speed and
              accuracy while..
            </div> */}
            <Row justify={"center"}>
              <Col>
                <Image
                  src={nuodataWeb}
                  preview={false}
                  className="productIntro_nuodataWebImg"
                />
              </Col>
            </Row>
            <Row justify={"center"}>
              <Col>
                <CommonButton
                  buttonText="Learn More"
                  link="https://nuodata.io/"
                  style={{ width: "28vh" }}
                  icon={<BsArrowRight />}
                  target="_blank"
                ></CommonButton>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={0} sm={0} md={0} lg={2} xl={2} xxl={2} />
        <Col xs={24} sm={24} md={24} lg={24} xl={9} xxl={9}>
          <Row className="productIntro_fyndrProductImgDiv">
            <Col>
              <Image
                src={fyndr}
                preview={false}
                className="productIntro_fyndrProductImg"
              />
            </Col>
          </Row>

          {/* <Row justify={"center"}>
            <Col className="productIntro_aboutfyndr">About Fyndr</Col>{" "}
          </Row> */}
          <div className="productIntro_aboutfyndrIntro">
            Empowering businesses with comprehensive, cost-effective solutions
            for online stores, offers, events, targeted promotions, and
            contactless payments
          </div>

          {/* <div className="productIntro_aboutfyndrIntro1">
            insights into your business and strategize your advertising to boost
            sales.
          </div> */}
          <Row justify={"center"}>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <Image
                src={fyndrWeb}
                preview={false}
                className="productIntro_fyndrWebImg"
              />
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col>
              <CommonButton
                buttonText="Learn More"
                link="https://fyndr.us/"
                style={{ width: "28vh" }}
                icon={<BsArrowRight />}
                target="_blank"
              ></CommonButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ProductInttroduction;
