import React from "react";
import { Row, Col, Carousel, Image } from "antd";
import { RightText } from "../../Components/RightText";
import RightImg from "../../assets/images/product/NuoData-product/BIG-DATA DRIVERS.webp";
import { ProductBanner } from "../../Components/ProductBanner";
import { CustomersAndPartners } from "../../Sections/HomePage/CustomerAndPartners";
import nuo_ss from "../../assets/images/product/NuoData-product/NuoData_screenshot.webp";
import connect from "../../assets/images/product/NuoData-product/connect.webp";
import design from "../../assets/images/product/NuoData-product/design.webp";
import validate from "../../assets/images/product/NuoData-product/validate.webp";
import transform from "../../assets/images/product/NuoData-product/transform.webp";
import productBanner from "../../assets/images/product/NuoData-product/Product_NuoData.webp";
import "./nuodata.scss";

const NuoDataProduct = (props: any) => {
  const rightTextItems = [
    {
      text: "Introducing Nuodata—revolutionizing data modernization and management. From compliance to insights, Nuodata transforms your data journey. With NuoData, you can modernize your enterprise data from ANY source to ANY cloud platform",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Experience data modernization, 6-10 times faster.",
      className: "paragraphText nuoDataText",
    },
    {
      text: "Witness 60-70% reduced costs, without compromise.",
      className: "paragraphText nuoDataText",
    },
    {
      text: "Achieve 100% accuracy—the single version of truth.",
      className: "paragraphText nuoDataText",
    },
    {
      text: "Empower your team with self-service validation. ",
      className: "paragraphText nuoDataText",
    },
    {
      text: "It’s a certified, hyper-secure and unified Data management platform for all data engineering, analytics, and operation needs.",
      className: "paragraphText nuoDataText",
    },
    {
      text: "Nuodata: Empowering Your Digital and Data Transformation Journey.",
      className: "paragraphText nuoDataText",
    },
  ];

  return (
    <>
      <div className="uiMain">
        <ProductBanner
          title="TRANSFORM YOUR DATA JOURNEY"
          link=""
          productBanner={productBanner}
          title2="One Platform for all Enterprise Data Modernization and Management Needs"
          btnTitle="Book a Demo Now"
          btnTitle2=""
        />
        <Row justify={"center"} align={"middle"}>
          <Col span={22} xs={22} sm={22}>
            <h5 className="commonTitleHead">ABOUT NUODATA</h5>
          </Col>
          <Col span={22} xs={22} sm={22} className="uiCenter">
            <div className="bdBlueOrangeGrad">
              <span>
                In today's data-driven world, innovation is paramount.{" "}
              </span>
            </div>
          </Col>
        </Row>
        <RightText
          rightTextItems={rightTextItems}
          rightTextImage={RightImg}
          link=""
          isBookBtn={true}
        />
        <Row justify={"center"} align={"middle"}>
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={24}
            xl={24}
            xxl={24}
            style={{ justifyContent: "center" }}
          >
            <div>
              <Carousel dots={false} slidesToShow={3} draggable autoplay>
                <div className="carouselItem">
                  <h5 className="h1TagText">Define</h5>
                  <Image src={nuo_ss} className="nuoData_crollImg" />
                </div>
                <div className="carouselItem">
                  <h5 className="h1TagText">Connect</h5>
                  <Image src={connect} className="nuoData_crollImg" />
                </div>
                <div className="carouselItem">
                  <h5 className="h1TagText">Design</h5>
                  <Image src={design} className="nuoData_crollImg" />
                </div>
                <div className="carouselItem">
                  <h5 className="h1TagText">Tranform</h5>
                  <Image src={transform} className="nuoData_crollImg" />
                </div>
                <div className="carouselItem">
                  <h5 className="h1TagText">Validate</h5>
                  <Image src={validate} className="nuoData_crollImg" />
                </div>
              </Carousel>
            </div>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={24}
            lg={0}
            xl={0}
            xxl={0}
            style={{ justifyContent: "center" }}
          >
            <div>
              <Carousel dots={false} slidesToShow={1} draggable autoplay>
                <div className="carouselItem">
                  <h5 className="h1TagText">Define</h5>
                  <Image
                    src={nuo_ss}
                    preview={false}
                    className="nuoData_crollImg"
                  />
                </div>
                <div className="carouselItem">
                  <h5 className="h1TagText">Connect</h5>
                  <Image
                    src={connect}
                    preview={false}
                    className="nuoData_crollImg"
                  />
                </div>
                <div className="carouselItem">
                  <h5 className="h1TagText">Design</h5>
                  <Image
                    src={design}
                    preview={false}
                    className="nuoData_crollImg"
                  />
                </div>
                <div className="carouselItem">
                  <h5 className="h1TagText">Tranform</h5>
                  <Image
                    src={transform}
                    preview={false}
                    className="nuoData_crollImg"
                  />
                </div>
                <div className="carouselItem">
                  <h5 className="h1TagText">Validate</h5>
                  <Image
                    src={validate}
                    preview={false}
                    className="nuoData_crollImg"
                  />
                </div>
              </Carousel>
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={0}
            lg={0}
            xl={0}
            xxl={0}
            style={{ justifyContent: "center" }}
          >
            <div>
              <Carousel dots={false} slidesToShow={1} draggable autoplay>
                <div className="carouselItem">
                  <h5 className="h1TagText">Define</h5>
                  <Image
                    src={nuo_ss}
                    preview={false}
                    className="nuoData_crollImg"
                  />
                </div>
                <div className="carouselItem">
                  <h5 className="h1TagText">Connect</h5>
                  <Image
                    src={connect}
                    preview={false}
                    className="nuoData_crollImg"
                  />
                </div>
                <div className="carouselItem">
                  <h5 className="h1TagText">Design</h5>
                  <Image
                    src={design}
                    preview={false}
                    className="nuoData_crollImg"
                  />
                </div>
                <div className="carouselItem">
                  <h5 className="h1TagText">Tranform</h5>
                  <Image
                    src={transform}
                    preview={false}
                    className="nuoData_crollImg"
                  />
                </div>
                <div className="carouselItem">
                  <h5 className="h1TagText">Validate</h5>
                  <Image
                    src={validate}
                    preview={false}
                    className="nuoData_crollImg"
                  />
                </div>
              </Carousel>
            </div>
          </Col>
        </Row>

        <CustomersAndPartners />
      </div>
    </>
  );
};

export default NuoDataProduct;
