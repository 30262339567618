import React from "react";
import "./GlobalPresences.scss";

import { Row } from "antd";
import Map from "../Maps/Maps";
import {NewTitle} from "../NewTitle"

interface Props {
  maps: {
    location: string;
    width: string;
    height: string;
    src: string;
    state: string;
    phoneNumber: string;
    phoneNumberCall:string;
    country: string;
    mail: string;
    image1:string;
    image2:string;
    image3:string;
  }[];

  text: string;
}

const GlobalPresences = ({ maps, text }: Props) => {
  return (
    <div className="globalPressence_blockings">
      <Row justify={"center"} className="globalPressence_row">
        <NewTitle text={text} textTitleClassName="textTitleClassName"/>
      </Row>
      <div className="">
        <div className="globalPressence_main_conatiner">
          <Row gutter={[32, 16]} justify={"start"}>
            {maps.map((card, i) => (
              <Map
                width={card.width}
                height={card.height}
                src={card.src}
                state={card.state}
                image1={card.image1}
                image2={card.image2}
                image3={card.image3}
                phoneNumber={card.phoneNumber}
                phoneNumberCall={card.phoneNumberCall}
                location={card.location}
                mail={card.mail}
                country={card.country}
                key={i}
              />
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default GlobalPresences;
