import React from "react";
import { Row, Col } from "antd";
import BannerBg from "../../assets/images/blog/blog-details/banner.webp";
import blogDetailImg1 from "../../assets/images/blog/blog-details/blogDetailImg1.webp";
import blogDetailImg2 from "../../assets/images/blog/blog-details/blogDetailImg2.webp";
import blogDetailImg3 from "../../assets/images/blog/blog-details/blogDetailImg3.webp";
import { HeroIndustries } from "../../Components/HeroIndustries";
import BlogRightText from "../../Components/BlogRightText/BlogRightText";
import BlogLeftText from "../../Components/BlogLeftText/BlogLeftText";
const BlogDetails = (props: any) => {
  return (
    <>
      <div className="uiMain">
        <HeroIndustries
          image={BannerBg}
          alt={"BannerBg"}
          pageName={"Blog  /  TRAVEL & HOSPITALITY"}
          pageIntro={"ADVANTAGES OF IMPLEMENTING RPA FOR CONTACT CENTERS"}
          pageIntroPara={"Navigating the Era of Unprecedented Change"}
        />

        <Row style={{ width: "100%", textAlign: "center" }} justify={"center"}>
          <Col
            md={19}
            xs={22}
            sm={22}
            className="headingParagraph"
            style={{ margin: "15vh 0 30px 0" }}
          >
            With the development and introduction of RPA, organizations are
            beginning to see huge growth in terms of savings and scaling of
            overall operations, however, when it comes to contact centres, RPA
            offers invaluable advantages by providing solutions to challenges
            based around service, process & technology.
          </Col>
          <Col
            md={19}
            xs={22}
            sm={22}
            className="headingParagraph"
            style={{ marginBottom: "30px" }}
          >
            Customer Experience is a huge KPI for any organization and call
            centres play a key role in shaping the success of that KPI. Customer
            Service Representative’s face immense demand to grow overall revenue
            by upselling & cross-selling, delivering fast, personalized and
            effective service – all while meeting high first call resolution and
            low average handling time goals. Yet, they happen to work with
            technology that slows them down and requires them to work with a
            myriad of slow & complicated applications while they speak with
            customers.
          </Col>
          <Col md={19} xs={22} sm={22} className="headingParagraph">
            Although Business Process Management plays its part, the high levels
            of repetitive tasks within contact centres can only be circumvented
            through call centre automation for better employee efficiency,
            customer satisfaction, and profitability. Take a look at how Robotic
            Process Automation in Contact Centres is improving customer
            engagement and streamlining processes –
          </Col>
          <Col md={19} xs={22} sm={22}>
            <BlogRightText
              rightTextImage={blogDetailImg1}
              rightTextTitle="Optimizing Operational Costs"
              rightText={`As businesses begin to grow, the hiring of new employees is both costly and time-consuming. Throw in the typically high churn rate in BPO markets, and the whole process becomes a pain on an organization’s capability to reach its optimal operation cost. With RPA, instead of hiring new employees, or even outsourcing labour, organizations can instead deploy robots to perform many of the same roles. In fact, a report by KPMG claims that labour automation through RPA can reduce an organization’s operational costs by 75% ! At the same time, RPA ‘s ready-made automation technology is fully adaptable, so it can be fully implemented without changing anything in the current system’s architecture with very less dependency on IT. In fact, most RPA software doesn’t require coding to program; the only real criterion is to know call centre workflows and processes. The result is that RPA empowers business call centres to manage their operation, and thus lessening their dependency on the IT department.`}
            />
            <BlogLeftText
              leftTextImage={blogDetailImg2}
              leftTextTitle="Boosting the Customer Experience"
              leftText={`On a strategic level, the real value of an automation tool that handles all the small, repetitive, everyday tasks that fuel daily operations within the call centre, such as opening cases, checking order status, record updates and much more, is in empowering call centres to deliver a superior customer experience. How so? First of all, all call centre staff can react to customer interactions in various ways, for better or for the worse. Therefore, depending on which agent the customer speaks with on a given day, that customer can have a drastically different experience. RPA brings consistency to the customer experience, while at the same time, RPA has a near zero percent error rate, so the experience is seamless for the customer.`}
            />
            <BlogRightText
              rightTextImage={blogDetailImg3}
              rightTextTitle="Boosting Revenue"
              rightText={`By enabling customer service representatives to increase customer satisfaction, RPA helps them to more effectively upsell and cross-sell for greater profitability. In addition, robots enable CSRs to reach their full sales potential by providing them with useful information in real time. For example, you can set a robot to automatically provide instructions (or even a full script) for speaking to a customer on the phone based on details in the customer’s file. And when a customer wants to make a purchase, the robot can streamline the process of recording the sale. \nAny call centre should thus, look very seriously at the myriad of advantages that RPA can bring to their processes, not just from the perspective of costs, but also from making sure that your team spends its time on more mission-critical and productive tasks.`}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BlogDetails;
