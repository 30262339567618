const ashishIntro = `Ashish, often referred to as Ash, is an industry veteran with over 25 years of experience, specializing in digital transformation for modern enterprises. He has collaborated with numerous Fortune 100 clients, offering his extensive expertise in Enterprise Data Management, Big Data, Analytics (encompassing Data Science, Machine Learning, and Artificial Intelligence), Cloud services (AWS, Google Cloud, Azure), Mobility, Blockchain, and Automation.

Ashish is distinguished by his proficiency in Strategic Large Deals, Business and Technology Consulting, Large Account Management, Strategy Development, Market Analysis, Global Delivery, Enterprise Architecture, P&L Management, Innovation, and Sales & Operations.`;

const ankitaIntro = `As a Director & Human Empowerment Lead, Ankita is responsible for creating unique experiences for our employees and customers. She is an expert in areas such as employee life cycle management, HR administration, and organizational development. Ankita has worked for various industries including E-commerce, retail, marketing, and financial services. In addition to talent & human empowerment, she also supports the branding & marketing activities for NucleusTeq platforms & products.`;

const kiranIntro = `Kiran is the Executive Vice President for NucleusTeq and is responsible for leading Business Transformation, Consulting, and Strategy. He is an industry leader with 25+ years of experience in Information Management, Data Strategy, Data Governance, Data Engineering, Cloud Transformation, and Organizational Change Management. Kiran has supported many Fortune 500 companies to drive large-scale Digital and Agile transformation through people, processes, and technology. Skilled to effectively 
collaborate with business and technology stakeholders in CDO and CTO organizations. He has worked for several Global Services companies with people management responsibility to hire, train, and mentor talent. He is an active contributor to go-to-market campaigns, industry events, trade shows, and start-up investor community forums.`;

const willIntro = `Will is responsible for building a world-class global sales operation for NucleusTeq. He is a customer-centric executive with over 25 years of operational excellence providing digital transformation within the Financial Services sector. Will enjoys building a culture of encouragement and empowerment so employees can accomplish great things and continually exceed customer expectations. Will believes that enthusiastic employees and customers fuel NucleusTeq’s rapid growth. He aims to harness that formula into a coveted organization that people want to work for and companies want to work with.`;

const swapnilIntro = `Passionate professional with around 14 years of experience in leading and managing modern application portfolios. Software developer at the core,Swapnil has worked on various vertical market software for the Telecommunications, Finance, and Retail industries. An advocate of emerging architectures on data modernization and proficient in Big-Data technologies, Google, AWS, Azure, and Hybrid Clouds to build scalable data-driven platforms and have partnered with multiple clients in their journey towards digital transformations. A strong believer in having a positive attitude toward any undertaking is the foundation to success. For NucleusTeq, he is spearheading services and deliveries for multiple clients and products`;

const deeptiIntro = `Deepti is an industry veteran and a passionate technology leader with over 25 years of experience. She has been instrumental in the development of NucleusTeq products that suit Fyndr and Nuodata. She has consulted many F100 customers and led their journey to budling modern data lakes and data-driven products. Deepti is proficient in the Cloud technologies AWS, GCP, and Azure; big data technologies such as Databricks, Spark, and Kafka; DevOps and MLOps using Vertex AI. She believes the ultimate aim of technology is to enable humans to offload the tasks that machines could easily do and use their true potential beyond the limits of physicality.`;

const kristenIntro = `Kristen Kalupski is a results-oriented and visionary senior marketing executive with over two decades of experience, serving as the CMO & Global Head of Partnerships and Alliances at NucleusTeq. With a proven track record, Kristen excels in developing and executing high-impact marketing strategies that drive business growth. Her expertise lies in crafting integrated marketing campaigns, nurturing brand identity, and ensuring outstanding customer experiences. Kristen's strength lies in harnessing the potential of data analytics and emerging technologies to optimize marketing performance and achieve organizational goals.`;

const majunathIntro = `Manjunath Siddaiah, Senior Director at NucleusTeq, brings over 18 years of IT and data transformation experience, specializing in enterprise-level solutions for clients such as AMEX, PetSmart, Capital One, and more. With a proven track record in large program delivery, he excels in data, digital transformation, and cloud technologies, ensuring successful outcomes and powerful business performance for clients. In his current role,Manjunath spearheads the creation of end-to-end data strategies for enterprise businesses, empowering and transforming the organization's data-driven initiatives. His strategic contributions include collaboration with tech leaders, and business executives, and effective vendor engagement to deliver efficient and innovative results. Before joining NucleusTeq, Manjunath held strategic leadership positions at Impetus and various consultancy experiences.`;

const visheshIntro = `Serving as the Head of Products Development has been an exhilarating journey marked by innovation and leadership. This role presents new challenges and opportunities daily, allowing for the continuous pushing of technological boundaries. Leading a team of incredibly talented professionals, the focus is on collaboration to design and implement solutions that address complex, real-world problems. The company’s emphasis on a supportive and collaborative work environment has been instrumental in fostering creativity and driving excellence. Continuous learning and professional growth are deeply ingrained in the culture, enabling the team to stay ahead in a rapidly evolving field. This role brings a deep sense of accomplishment and fulfillment, with a commitment to contributing to the company's continued success and innovation.`;

const anandIntro = `With over 25+ years of experience, Anand brings extensive go-to-market leadership experience in Cloud, Big Data, AI, and Analytics, focusing on enterprise customers across various industries. Anand has helped numerous organizations succeed with data engineering and analytics use cases, utilizing innovations in Intelligent Lakehouses and Streaming Data technologies. Anand is passionate about leading cohesive teams to implement data cloud and AI technologies that positively impact humanity.`

module.exports = {
  ashishIntro,
  willIntro,
  swapnilIntro,
  ankitaIntro,
  kiranIntro,
  deeptiIntro,
  kristenIntro,
  majunathIntro,
  visheshIntro,
  anandIntro
};
