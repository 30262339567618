import React from "react";
import { Row } from "antd";
import { CustomersAndPartners } from "../../Sections/HomePage/CustomerAndPartners";
import TermsBanner from "../../Components/TermsBanner/TermsBanner";
import bannerImage from "../../assets/images/privacy/privacy_banner.webp";
import TermsTab from "../../Components/TermsTab/TermsTab";

const Privacy = () => {
  const tabData = [
    {
      title: "Introduction",
      key: "1",
      content:
        "Welcome to Nucleuteq.com. At NucleusTeq.com, we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you visit our website.",
    },
    {
      title: "Information We Collect",
      key: "2",
      content:
        "We Collect Personal Information: When you visit our website, we may collect certain personally identifiable information, such as your name, email address, postal address, and phone number, if provided voluntarily.\n Non-Personal Information: We may also collect non-personal information, such as browser type, IP address, device information, and cookies, to enhance your user experience.",
    },
    {
      title: "How We Use Your Information",
      key: "3",
      content:
        "We may use your information for the following purposes: To provide and maintain our website's functionality and services.\n To communicate with you and respond to your inquiries. To personalize your experience and improve our website. To send updates, newsletters, and promotional materials, if you've opted to receive them. To comply with legal obligations.",
    },
    {
      title: "Cookies and Tracking Technologies",
      key: "4",
      content:
        "Our website may use cookies and similar tracking technologies to collect and store certain information about your browsing preferences.\n You can manage cookie preferences through your browser settings.",
    },
    {
      title: "Data Security",
      key: "5",
      content:
        "We implement reasonable security measures to protect your data; however, no data transmission over the internet is completely secure.\n We cannot guarantee the security of your information.",
    },
    {
      title: "Third-Party Links",
      key: "6",
      content:
        "Our website may contain links to third-party websites or services. We have no control over their content or practices and are not responsible for their privacy policies.",
    },
    {
      title: "Your Privacy Choices",
      key: "7",
      content:
        "You may have options regarding the use of your data, including opting out of promotional communications or updating your personal information. Please contact us to exercise your choices.",
    },
    {
      title: "Changes to this Privacy Policy",
      key: "8",
      content:
        "We may update this Privacy Policy to reflect changes in our practices or for legal reasons. We will notify you of any significant updates.",
    },
    {
      title: "Contact Us",
      key: "9",
      content:
        "If you have questions or concerns about this Privacy Policy or our data practices, please contact us at ops@nucleusteq.com",
    },
  ];
  return (
    <>
      <div className="uiMain">
        <TermsBanner
          bannerText={"Privacy Statement"}
          bannerImage={bannerImage}
        />
        <Row justify={"center"} align={"middle"}>
          <TermsTab tabData={tabData} tabTitle="Privacy Statement Structure" />

          <CustomersAndPartners />
        </Row>
      </div>
    </>
  );
};

export default Privacy;
