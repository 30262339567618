import React, { useState, useEffect, useRef } from "react";

const Event = () => {
  const [iframeHeight, setIframeHeight] = useState("600px");
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.height) {
        setIframeHeight(event.data.height + "px");
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  return (
    <div>
      <iframe
        ref={iframeRef}
        src="https://resources.nucleusteq.com/executiveroundtableapril17"
        width="100%"
        height={iframeHeight}
        style={{ border: "none" }}
        scrolling="no"
      ></iframe>
    </div>
  );
};

export default Event;
