import "./circleImage.scss";
import { Col, Space, Row } from "antd";
import leadership_linkedin from "../../assets/images/leadership/leadership_linkedin.webp";
import leadership_mail from "../../assets/images/leadership/leadership_mail.webp";
import "@fortawesome/fontawesome-free/css/all.css";

interface Props {
  img: string;
  routeLinkedin: string;
  routeMail: string;
}

const CirleImage = ({ img, routeMail, routeLinkedin }: Props) => {
  return (
    <>
      <Row justify={"center"}>
        <img
          src={img}
          className="circleImage"
          alt="circleimage"
          loading="lazy"
        ></img>
      </Row>
      <Row align={"bottom"} justify={"center"} className="circleImage_icons">
        <Space size={"large"}>
          <Col
            onClick={() => {
              window.open(routeLinkedin, "_blank");
            }}
          >
            <img
              src={leadership_linkedin}
              alt="linkedin"
              loading="lazy"
              className="circleImage_linkMail"
            />
          </Col>
          <Col
            onClick={() => (window.location.href = `mailto:${routeMail}`)}
            style={{ paddingTop: ".5vw" }}
          >
            <img
              src={leadership_mail}
              alt="email"
              loading="lazy"
              className="circleImage_linkMail"
            />
          </Col>
        </Space>
      </Row>
    </>
  );
};

export default CirleImage;
