import React from "react";
// import "./termsBanner.scss";
import { Row, Col, Layout } from "antd";
import BannerBg from "../../assets/images/terms/Privacy.webp";

const TermsBanner = () => {
  const { Content } = Layout;

  return (
    <Layout className="carousel">
      <div>
        <div
          className="termsBannerImg"
          style={{
            backgroundImage: `url(${BannerBg})`,
            backgroundColor: "#1D1A36",
          }}
        >
          <Row
            justify={"center"}
            align={"middle"}
            style={{ width: "100%", height: "100%" }}
          >
            <Col xs={23} sm={23} md={23} lg={23} xl={23} xxl={23}>
              <h1 className="termsBannerHead">Privacy Statement </h1>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default TermsBanner;
