import React, { useEffect, useState } from "react";
import { Row } from "antd";
import { details } from "../../mocks/sucessStoriesData";
import { useLocation } from "react-router-dom";

import OverviewSucessStory from "../../Components/OverviewSuccessStory/OverviewSucessStory";
import SuccessStoriesScroll from "../../Components/SucessStoriesScroll/SucessStoriesScroll";
import success_scroll1 from "../../assets/images/successStories1/success_scroll-Img1.webp";
import success_scroll2 from "../../assets/images/successStories1/success_scroll-Img2.webp";
import success_scroll3 from "../../assets/images/successStories1/success_scroll-Img3.webp";
import bg from "../../assets/images/successStories1/bg.webp";
import sentiments from "../../assets/images/successStories1/sentiments.webp";
import realtime from "../../assets/images/successStories1/realtime.webp"
import backofficee from "../../assets/images/successStories1/backofficee.webp"
import liabilities from "../../assets/images/successStories1/liability.webp"
import real_time from "../../assets/images/successStories1/real-time.webp"
import modern from "../../assets/images/successStories1/modern.webp"
import digital from "../../assets/images/successStories1/digital.webp"
import lake from "../../assets/images/successStories1/lake.webp"
import enterprise from "../../assets/images/successStories1/enterprise.webp"
import detec from "../../assets/images/successStories1/detec.webp"

import MainHeadingForAll from "../../Components/MainHeadingForAll/MainHeadingForAll";

const OverviewSuccess = (props: any) => {
  const location = useLocation();

  const [newlocation, setNewlocation] = useState<String>(
    location.search.slice(6, location.search.length)
  );
  var index = details.findIndex((details) => details.name === newlocation);
  const [projectData, setProjectData] = useState(details[index]);

  useEffect(() => {
    setProjectData(details[index]);
  }, [index]);
  // console.log(JSON.stringify(projectData));

  useEffect(() => {
    let newParam = location.search.slice(6, location.search.length);
    // console.log(newParam);
    setNewlocation(newParam);
  }, [location]);

  const sucessStories = [
    {
      src: real_time,
      info: "Real-time insights and recommendation engine for one of the largest pharmaceuticals company..",
      route: "/overview?type=realtime",
    },
    {
      src: bg,
      info: "Big-Data lake setup on Azure cloud for top 3 pet retailers in the world",
      route: "/overview?type=bigdata",
    },
    {
      src: sentiments,
      info: "Real-time insights and recommendation engine for one of the largest pharmaceuticals company..",
      route: "/overview?type=sentiment",
    },
    {
      src: modern,
      info: "Enterprise Data Modernization of one of world’s largest credit card company from Teradata to..",
      route: "/overview?type=modernization",
    },
    {
      src: detec,
      info: "Detection to Intent to Fraud using NPL for one of world’s largest banks",
      route: "/overview?type=detection",
    },
    {
      src: liabilities,
      info: "Modernization of Enterprise reporting (Netezza) to AWS for a major housing finance company",
      route: "/overview?type=newLiability",
    },
    {
      src: backofficee,
      info: "Back Office RPA Solution for American Multinational investment",
      route: "/overview?type=backOffice",
    },
    {
      src: digital,
      info: "Digital Transformation for one of the largest oil and Gas company in the world.",
      route: "/overview?type=digitalTransformation",
    },
    {
      src: realtime,
      info: "Realtime anomaly detection & network monitoring for major telecommunications companies in the USA.",
      route: "/overview?type=realtimeAnomaly",
    },
    {
      src: lake,
      info: "End-to-End Data Lake Setup & Migration of Netezza reporting platform to Azure for a Major retailer",
      route: "/overview?type=datalake",
    },
    {
      src: enterprise,
      info: "Create new liability insurance models based on IOT sensor data for a leading business Insurer.",
      route: "/overview?type=enterprise",
    },
    
  ];
  return (
    <div style={{ background: "#1d1a36" }}>
      <OverviewSucessStory projectData={projectData} />
      <Row justify={"center"}>
        <MainHeadingForAll
          mainHeadingForAll="OUR SUCCESS STORIES"
          color="#FFF"
        />
      </Row>
      <SuccessStoriesScroll
        sucessStories={sucessStories}
        onCardClick={({ e }: any) => {
          // console.log("oncard", e);
        }}
      />
    </div>
  );
};
export default OverviewSuccess;
