import modernization from "../assets/images/successStories1/modernization.webp";
import bigData from "../assets/images/successStories1/Big-Data.webp";
import backoffice from "../assets/images/successStories1/back-office.webp";
import automation from "../assets/images/successStories1/automation.webp";
import enterprise from "../assets/images/successStories1/enterprise.webp";
import realtimeAnomaly from "../assets/images/successStories1/real-time.webp";
import digitaltransformation from "../assets/images/successStories1/digitalTransformation.webp";
import detection from "../assets/images/successStories1/detection.webp";
import newliability from "../assets/images/successStories1/liability.webp";
import realtime from "../assets/images/successStories1/realtime.webp";
import sentiment from "../assets/images/successStories1/sentiment.webp";

export const details = [
  {
    name: "modernization",
    image: modernization,
    pageIntro:
      "MODERNIZATION OF ENTERPRISE REPORTING TO AWS FOR A MAJOR HOUSING FINANCE COMPANY",
    about:
      "About the customer – Customer is one of the top federal housing finance company",
    content: [
      {
        heading: "CUSTOMER CHALLENGE",
        subheading: "They faced critical challenges",
        para1:
          "Migrating the Enterprise Data Warehouse (EDW) from Netezza to the AWS Cloud.",
        para2:
          "Designing and delivering a comprehensive solution aligned with enterprise data lake objectives.",
        para3:
          "Replicating the current Netezza snapshotting process within the AWS environment.",
        para4:
          "Providing best practice recommendations for metadata capture, lineage, and audit.",
        para5: "",
      },
      {
        heading: "NUCLEUSTEQ SOLUTION",
        subheading: "Our approach was comprehensive and collaborative",
        para1:
          "Migrated the snapshotting process from Netezza to the AWS environment.",
        para2:
          "Migrated base tables in Third Normal Form (3NF) from the Integrated Data Store (IDS) to S3, updating them incrementally.",
        para3:
          "Implemented data migration patterns for reusable tables conforming to similar patterns.",
        para4:
          "Identified and migrated a significant percentage of EDW users and their associated queries, qualifying as long-running workloads, to S3.",
        para5:
          "Delivered a comprehensive AWS architecture aligned with enterprise data lake objectives, encompassing best practice recommendations for metadata capture, lineage, storage, and audit.",
      },
      {
        heading: "Outcomes Delivered",
        subheading: "The results were transformative",
        para1:
          "Successfully offloaded a significant percentage of the Netezza-based EDW, including data and workloads, to the AWS environment.",
        para2:
          " Freed the EDW from the snapshotting process by migrating it to AWS.",
        para3: "Leveraged cloud elasticity for future scalability.",
        para4: "",
        para5: "",
      },
    ],
  },
  {
    name: "bigdata",
    image: bigData,
    pageIntro:
      "JOURNEY TO CLOUD: BIG DATA LAKE ON AZURE FOR TOP PET RETAILER WORLDWIDE",
    about: "",
    content: [
      {
        heading: "CUSTOMER CHALLENGE",
        subheading: "They faced critical challenges",
        para1:
          "A lack of centralization, standardization, and self-service access to enterprise data, leading to extended time-to-market, elevated costs, and operational redundancies",
        para2:
          "Development projects were conducted in isolation rather than adopting a platform/product approach, resulting in substantial costs and redundancy.",
        para3:
          "Replicating the current Netezza snapshotting process within the AWS environment.",
        para4:
          "Providing best practice recommendations for metadata capture, lineage, and audit.",
        para5: "",
      },
      {
        heading: "NUCLEUSTEQ SOLUTION",
        subheading: "Our approach was comprehensive",
        para1:
          "We defined and created core platform components on the Azure environment, encompassing Scheduling, Ingestion, Metadata Management, Data Quality, and more.",
        para2:
          " Established an end-to-end monitoring and operations management platform to ensure optimal platform utilization.",
        para3:
          "Implemented identified data migration patterns in a way that it can be reused for tables conforming to similar patterns",
        para4:
          "Introduced Continuous Integration, Continuous Deployment, and Automated Testing capabilities for all federated applications, leveraging enterprise Jenkins",
        para5:
          "Created a data consumption layer through a microservices architecture using Azure Kubernetes Services.",
      },
      {
        heading: "Outcomes Delivered",
        subheading: "The results were transformative",
        para1:
          "Established a unified single version of truth data lake for the entire enterprise.",
        para2:
          "Achieved a remarkable 5X faster time to market for new reports and data capabilities.",
        para3:
          " Realized a substantial 40% reduction in operating expenses through cloud adoption and management.",
        para4: "",
        para5: "",
      },
    ],
  },
  {
    name: "backOffice",
    image: backoffice,
    pageIntro: "BACK OFFICE RPA TRANSFORMATION FOR A MAJOR INVESTMENT BANK",
    about:
      "Back Office RPA Solution for American Multinational investment bank and financial services company, also Big 4 Bank with around 10.73% of American bank deposits",
    content: [
      {
        heading: "CUSTOMER CHALLENGE",
        subheading: "They faced several pressing challenges",
        para1: "Extended waiting times for Mortgage Processing.",
        para2: "A mandate to reduce errors and paperwork in the process.",
        para3: " A highly manual process with redundant and repetitive steps.",
        para4: "The mission to enhance Customer Satisfaction Ratings.",
        para5: "",
      },
      {
        heading: "NUCLEUSTEQ SOLUTION",
        subheading: "Our approach was comprehensive and transformational",
        para1:
          "We employed Robotic Process Automation (RPA) to automate manual and repetitive entries in application processing.",
        para2:
          "We digitized paperwork through OCR, introduced easier online applications and verification, accelerated underwriting, and streamlined escalation processing, reducing process time to an impressive 1 minute from the previous 2-hour approvals.",
        para3:
          "Introduced Continuous Integration, Continuous Deployment, and Automated Testing capabilities for all federated applications, leveraging enterprise Jenkins",
        para4:
          "Created a data consumption layer through a microservices architecture using Azure Kubernetes Services.",
        para5: "",
      },
      {
        heading: "OUTCOMES DELIVERED",
        subheading: "The results were transformative",
        para1:
          "Established a unified single version of truth data lake for the entire enterprise.",
        para2:
          "Achieved a remarkable 5X faster time to market for new reports and data capabilities.",
        para3:
          " Realized a substantial 40% reduction in operating expenses through cloud adoption and management.",
        para4: "",
        para5: "",
      },
    ],
  },
  {
    name: "automation",
    image: automation,
    pageIntro:
      "AUTOMATION OF INSURANCE – SALES, UNDERWRITING & SERVICES PROCESS FOR LEADING INSURANCE COMPANY WITH $135 B IN ASSETS",
    about: "",
    content: [
      {
        heading: "CUSTOMER CHALLENGE",
        subheading: "CUSTOMER CHALLENGE",
        para1: "Mandate to Increase revenues & customer retention",
        para2: "Reduce customer support costs",
        para3: "Mandate to Improve customer experience",
        para4: "",
        para5: "",
      },
      {
        heading: "NUCLEUSTEQ SOLUTION",
        subheading: "CUSTOMER CHALLENGE",
        para1: "Billing Inquiry Analysis",
        para2: "Insurance Quote Lookup",
        para3: "Knowledge Push",
        para4: "Automated Documentation",
        para5:
          "Agent Groups: Customer Service, Sales, Underwriting, Processing",
      },
      {
        heading: "OUTCOMES DELIVERED",
        subheading: "CUSTOMER CHALLENGE",
        para1: "72% Manual Effort Automated",
        para2: "6 million in annual savings",
        para3: "2 weeks CSR training time reduction",
        para4: "20% AHT reduction",
        para5: "6 months implementation",
      },
    ],
  },
  {
    name: "detection",
    image: detection,
    pageIntro:
      "UNVEILING FRAUD: NLP-POWERED DETECTION FOR ONE OF THE WORLD’S LARGEST BANKS",
    about: "Enhancing Compliance and Safeguarding Reputation",
    content: [
      {
        heading: "CUSTOMER CHALLENGE",
        subheading: "They faced pressing challenges",
        para1:
          "Substantial losses amounting to billions of dollars in penalties and trading losses.",
        para2:
          "The imperative to proactively detect the intent to commit fraud by monitoring communications, avoiding penalties, and safeguarding their reputation.",
        para3:
          "A large compliance team of approximately 30 individuals dedicated to using keyword searches, resulting in a high rate of false positives.",
        para4: "",
        para5: "",
      },
      {
        heading: "NUCLEUSTEQ SOLUTION",
        subheading: "Our comprehensive approach",
        para1:
          "Delivered a semi-supervised learning-based NLP solution to enhance fraudulent activity detection.",
        para2:
          " Coordinated multiple rounds of reviews to enhance solution accuracy across 10 distinct categories.",
        para3: "Achieved the expected precision in SLA within a mere 2 months.",
        para4:
          " Implemented the solution using cutting-edge technologies, including Stanford NLP, Spark, and Tachyon.",
        para5: "",
      },
      {
        heading: "OUTCOMES DELIVERED",
        subheading: "The results were transformative",
        para1:
          "Delivered expected accuracy in detecting email messages with fraud scores",
        para2:
          "Significantly reduced data by identifying non-relevant messages using learning algorithms",
        para3:
          "Established unique templates for each type of fraud based on the presence of different entities such as individuals and locations.",
        para4:
          "Developed an innovative approach to profile all users in the data through graph analytics algorithms.",
        para5: "",
      },
    ],
  },
  {
    name: "newLiability",
    image: newliability,
    pageIntro:
      "REVOLUTIONIZING LIABILITY INSURANCE MODELS: IOT-POWERED TRANSFORMATION FOR A LEADING BUSINESS INSURER",
    about: "Shaping the Future of Risk Assessment and Underwriting",
    content: [
      {
        heading: "CUSTOMER CHALLENGE",
        subheading: "They faced pressing challenges",
        para1: "Extended waiting times for Mortgage Processing.",
        para2: "A mandate to reduce errors and paperwork in the process.",
        para3: " A highly manual process with redundant and repetitive steps.",
        para4: "The mission to enhance Customer Satisfaction Ratings.",
        para5: "",
      },
      {
        heading: "NUCLEUSTEQ SOLUTION",
        subheading: "Our comprehensive approach",
        para1:
          "We employed Robotic Process Automation (RPA) to automate manual and repetitive entries in application processing.",
        para2:
          "We digitized paperwork through OCR, introduced easier online applications and verification, accelerated underwriting, and streamlined escalation processing, reducing process time to an impressive 1 minute from the previous 2-hour approvals.",
        para3:
          "Introduced Continuous Integration, Continuous Deployment, and Automated Testing capabilities for all federated applications, leveraging enterprise Jenkins",
        para4:
          "Introduced Continuous Integration, Continuous Deployment, and Automated Testing capabilities for all federated applications, leveraging enterprise Jenkins",
        para5: "",
      },
      {
        heading: "OUTCOMES DELIVERED",
        subheading: "The results were transformative",
        para1:
          " Established a Data Lake on Azure for unified data management and a single source of truth.",
        para2: "Achieved data integrity and quality with financial benefits.",
        para3:
          " Implemented a real-time system (processing 100 transactions per second) for insurance underwriting and premium processing.",
        para4: "Slashed system redundancy by a remarkable 40%.",
        para5: "",
      },
    ],
  },
  {
    name: "realtime",
    image: realtime,
    pageIntro:
      "REAL-TIME INSIGHTS AND RECOMMENDATION ENGINE FOR ONE OF THE LARGEST PHARMACEUTICALS COMPANY IN THE WORLD",
    about: "Revolutionizing Data Management in Big Pharma",
    content: [
      {
        heading: "CUSTOMER CHALLENGE",
        subheading: "The client grappled with a series of pressing challenges:",
        para1:
          " An unmanaged on-prem data lake, at risk of becoming a data swamp, required optimization and migration to the cloud.",
        para2:
          "Existing insights and recommendations for enterprise users were unusable.",
        para3:
          "Inefficient utilization of provisioned clusters hindered progress.",
        para4:
          "The need to establish an organized data lake with a curated semantic layer to reduce redundancy and confusion.",
        para5:
          "Breaking down silos and creating a consolidated reporting platform powered by Hadoop.",
      },
      {
        heading: "NUCLEUSTEQ SOLUTION",
        subheading: "CUSTOMER CHALLENGE",
        para1:
          "Performed aspect-based sentiment analysis and identified potential areas of improvement",
        para2:
          "Worked on linkage with other datasets to identify the root causes of the problem and suggest system improvement changes",
        para3: "Provide high level recommendations based on past actions taken",
        para4: "",
        para5: "",
      },
      {
        heading: "OUTCOMES DELIVERED",
        subheading: "Our approach was thorough and collaborative",
        para1:
          "We engaged closely with the client's IT and business stakeholders to develop a comprehensive roadmap and strategy for optimizing and migrating to the cloud.",
        para2:
          "We defined a multi-year phased cloud migration plan, ensuring success in execution.",
        para3:
          "Our team enabled and trained internal teams to harness the power of the cloud and big data at the end-user level.",
        para4:
          "We executed an initiative to migrate EDW workloads to Hadoop on the cloud, optimizing data harmonization in the process.",
        para5:
          "Siloed analytics were converged into a uniform platform, facilitating easy discovery and multi-faceted analysis.",
      },
    ],
  },
  {
    name: "digitalTransformation",
    image: digitaltransformation,
    pageIntro:
      "DIGITAL TRANSFORMATION FOR ONE THE WORLD’S BIGGEST OIL AND GAS COMPANIES ",
    about:
      " Data innovation meets digital transformation for the oil and gas industry",
    content: [
      {
        heading: "CUSTOMER CHALLENGE",
        subheading:
          "Fragmentation plagued the organization, causing substantial delays and suboptimal recommendations",
        para1:
          " A lack of a unified digital strategy further exacerbated execution delays.",
        para2: "Valuable insights were not translating into effective actions.",
        para3: "",
        para4: "",
        para5: "",
      },
      {
        heading: "NUCLEUSTEQ SOLUTION",
        subheading: "",
        para1:
          "We engineered a transformative shift by defining and executing an Azure-based cloud platform strategy, leveraging the cutting-edge Industry Gen 4 reference architecture.",
        para2:
          "Our implementation included over 10 insights designed to drive intelligent decision-making, from optimizing operations to real-time monitoring.",
        para3:
          "We embraced IoT streaming and real-time analytics to predict failures before they occurred.",
        para4: "",
        para5: "",
      },
      {
        heading: "OUTCOMES DELIVERED",
        subheading: "Our results spoke volumes",
        para1:
          "A remarkable 15% increase in yield thanks to advanced Machine Learning (ML) recommendations.",
        para2:
          " A 10% reduction in time to market, courtesy of our cloud-based digital platform.",
        para3:
          "A powerful 12-15% drop in failures and incidents, achieved through predictive monitoring.",
        para4: "",
        para5: "",
      },
    ],
  },
  {
    name: "realtimeAnomaly",
    image: realtimeAnomaly,
    pageIntro:
      "REAL-TIME ANOMALY DETECTION & NETWORK MONITORING TRANSFORMATION FOR A TOP US TELECOMMUNICATIONS GIANT",
    about: "Redefining the future of telecommunications",
    content: [
      {
        heading: "CUSTOMER CHALLENGE",
        subheading:
          "They faced critical challenges in their network operations.",
        para1:
          "They needed to detect router issues, tower failures, and call drops in real time.",
        para2:
          "Swiftly rerouting traffic to functioning infrastructure was essential",
        para3:
          "Swiftly rerouting traffic to functioning infrastructure was essential",
        para4: "",
        para5: "",
      },
      {
        heading: "NUCLEUSTEQ SOLUTION",
        subheading:
          "We orchestrated a comprehensive solution to address their challenges.",
        para1:
          "NucleusTeq engineered an end-to-end pipeline that ingests, transforms, and dynamically analyzes network data using cutting-edge Machine Learning Algorithms.",
        para2:
          "We also implemented real-time routing and monitoring of the network to ensure seamless operations.",
        para3: "",
        para4: "",
        para5: "",
      },
      {
        heading: "OUTCOMES DELIVERED",
        subheading: "Our results were nothing short of remarkable",
        para1:
          "Unsupervised algorithm dynamically updates itself and identifies anomalies. Thus, it always considers the latest dynamics of the environment",
        para2:
          "This approach enabled real-time monitoring and optimization of the infrastructure, resulting in an elevated customer experience that set new industry standards.",
        para3: "",
        para4: "",
        para5: "",
      },
    ],
  },
  {
    name: "enterprise",
    image: enterprise,
    pageIntro: "ENTERPRISE DATA MODERNIZATION FOR A GLOBAL CREDIT CARD COMPANY",
    about: " Data Modernization for the financial services industry ",
    content: [
      {
        heading: "CUSTOMER CHALLENGE",
        subheading: "They faced a series of monumental challenges",
        para1:
          "Teradata, their sole platform for enterprise reporting, resulted in significant annual costs.",
        para2:
          "Data was locked in silos, with each business unit employing its own data exchange processes, causing significant delays in product development requiring enterprise-level data.",
        para3:
          "A lack of self-service access to enterprise data led to extended time-to-market, increased costs, and redundancies.",
        para4:
          "The need to establish an organized data lake with a curated semantic layer to reduce redundancy and confusion.",
        para5:
          "Breaking down silos and creating a consolidated reporting platform powered by Hadoop.",
      },
      {
        heading: "NUCLEUSTEQ SOLUTION",
        subheading: "We engineered a transformative solution:",
        para1: "Centralized, Standardized & Certified Data for Consistency",
        para2:
          "Democratize Data through self-service API’s while eliminating redundancy",
        para3: "Ensuring compliance to Enterprise data security guidelines",
        para4: "Maximize application/data Reusability & Globalization",
        para5: "",
      },
      {
        heading: "OUTCOMES DELIVERED",
        subheading: "Our results are powerful",
        para1:
          "Comprehensive Metadata Management, Security Data Quality & Governance",
        para2: "Establishment of a single source of truth.",
        para3: "Improved data integrity and quality, with financial benefits.",
        para4: "A remarkable 47% reduction in redundancy",
        para5: "A staggering 72% reduction in Attribute SORs.",
        para6: "Migration of a massive 4 petabytes of data.",
        para7: " All achieved within an impressive 18-month delivery timeline.",
        para8: "Enabling an annual operational savings of over $35 million.",
      },
    ],
  },
  {
    name: "sentiment",
    image: sentiment,
    pageIntro: "TRANSFORMING CUSTOMER SENTIMENT FOR A MAJOR US AIRLINE",
    about: "Setting new industry standards in the travel industry",
    content: [
      {
        heading: "CUSTOMER CHALLENGE",
        subheading: "",
        para1:
          "We embarked on a mission to redefine how a major US airline was perceived, both nationally and internationally.",
        para2:
          "Our task was clear: identify and reshape customer sentiment across various platforms like Facebook, Twitter, Email Support, Call Center Support, Website, and Travel Booking sites.",
        para3:
          "The challenge? Linking these sentiments to passengers, travel details, flight times, sectors, aircraft, and staff.",
        para4: "",
        para5: "",
      },
      {
        heading: "NUCLEUSTEQ SOLUTION",
        subheading: "",
        para1:
          "We harnessed the power of aspect-based sentiment analysis to pinpoint key areas for improvement.",
        para2:
          "Our data experts then delved into the data, making connections with diverse datasets to uncover the root causes behind customer sentiment issues and offering actionable suggestions for system enhancements.",
        para3: "",
        para4: "",
        para5: "",
      },
      {
        heading: "OUTCOMES DELIVERED",
        subheading: "The results exceeded expectations",
        para1:
          "We achieved an impressive accuracy rate of over 80% in identifying relevant sentiments that directly influenced customer satisfaction.",
        para2:
          "Our recommendations were not just theoretical; they were practical and effective, offering tailored solutions for specific cases.",
        para3: "",
        para4: "",
        para5: "",
        para6: "",
        para7: "",
        para8: "",
      },
    ],
  },
  {
    name: "datalake",
    image: enterprise,
    pageIntro:
      "REVOLUTIONIZING DATA MANAGEMENT FOR A LEADING GLOBAL RETAILER: AZURE MIGRATION AND DATA LAKE SETUP",
    about: "A Journey from Redundancy to Efficiency",
    content: [
      {
        heading: "CUSTOMER CHALLENGE",
        subheading: "They faced several critical challenges",
        para1:
          " A lack of centralization, standardization, and self-service access to enterprise data, resulting in extended time-to-market, elevated costs, and operational redundancies.",
        para2:
          "Point-to-point integrations led to substantial costs, a lack of governance, data quality issues, and the absence of a single version of the truth.",
        para3: "",
        para4: "",
        para5: "",
      },
      {
        heading: "NUCLEUSTEQ SOLUTION",
        subheading: "Our approach was comprehensive and transformative",
        para1:
          "Centralized, standardized, and certified data to ensure uniformity and consistency.",
        para2:
          "Democratized data access through self-service APIs, eliminating redundancy and streamlining operations",
        para3:
          " Ensured strict compliance with enterprise data security guidelines.",
        para4:
          "Maximized application and data reusability to drive globalization and efficiency.",
        para5: "",
      },
      {
        heading: "OUTCOMES DELIVERED",
        subheading: "Our results were nothing short of remarkable",
        para1:
          "Established a Data Lake on Azure, providing a modern data management solution.",
        para2:
          " Implemented comprehensive metadata management, enhanced security, and governance protocols.",
        para3:
          "Achieved a single source of truth, enhancing data integrity and quality with financial benefits.",
        para4:
          "Slashed redundancy by an impressive 40% and reduced IT infrastructure costs by 52%.",
        para5:
          "Lowered project costs by 45%, expediting project delivery by 20% through offshore leverage.",
        para6: "Seamlessly migrated a staggering 100 terabytes of data.",
        para7:
          "Realized operations savings of over $3 million per year across customer and services business units.",
        para8:
          "Anticipated 50% more customer retention and repeat business.Expected a 12-15% increase in sales based on tailored offers.",
      },
    ],
  },
];
