import React from "react";
import Hero from "../../Components/HeroIndustries/HeroIndustries";
import { CommunicationCard } from "../../Components/CommunicationCard";
import { Accomplish } from "../../Components/Accomplishment";
import OurSuccessStory from "../../Components/OurSuccessStory/OurSuccessStory";
import bgImg5 from "../../assets/images/energy/ENERGY_UTILITIES.webp";
import energyCardImg1 from "../../assets/images/energy/energy_cardImg1.webp";
import energyCardImg2 from "../../assets/images/energy/energy_cardImg2.webp";
import energyCardImg3 from "../../assets/images/energy/energy_cardImg3.webp";
import squarImg from "../../assets/images/acoomplish_component/accomplishImg.webp";
import commImg from "../../assets/images/energy/Realtime.webp";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import { InfoBankingServices } from "../../Components/InfoBankingServices";
import { CommunicationHeading } from "../../Components/CommunicationHeading";
import { NewText } from "../../Components/NewText";
import { OrangeBar } from "../../Components/OrangeBar";

const dataCom = [
  {
    comCardImg1: energyCardImg1,
    linkAnchor: "",
    comCardText1: "Consumer Product Development",
    CardText1:
      "Transform the customer experience at service stations while harnessing the power of digital technologies to drive growth.",
  },
  {
    comCardImg1: energyCardImg2,
    linkAnchor: "",
    comCardText1: "Supply Chain and Operations",
    CardText1:
      " Create a real-time view of demand-supply dynamics to optimize operations and ensure efficient energy delivery.",
  },
  {
    comCardImg1: energyCardImg3,
    linkAnchor: "",
    comCardText1: "Technology Advisory & Engineering",
    CardText1:
      "For the utilities sector, we build true digital enterprises by leveraging Cloud, Analytics, Big Data, and Blockchain, fortifying your assets and securing your future.",
  },
];

function Energy() {
  FunctionTitles("NucleusTeq | Energy & Utilities");
  return (
    <>
      <Hero
        pageName="INDUSTRIES  /  ENERGY & UTILITIES"
        pageIntro="FUELING THE FUTURE OF ENERGY & UTILITIES"
        pageIntroPara="Navigating the Complex Landscape with Innovation and Customer Focus"
        alt="img"
        image={bgImg5}
      />

      <InfoBankingServices
        heading="ENERGY & UTILITIES"
        alt="Image"
        para1="In an era defined by oil price volatility and rapid changes in the energy sector, NucleusTeq is at the forefront of transformation. We recognize that digital technologies, the drive for greener energy solutions, and the demand for consumer-centric services are reshaping the industry. Our mission is twofold: to introduce digital innovation for competitive advantage and to place customers at the core of business strategies, earning their trust in a complex business landscape."
        para2=""
        para3=""
      />
      <CommunicationHeading cardHeadingText="OUR CAPABILITIES" />
      <CommunicationCard servicedata={dataCom} />
      <NewText newtext="NucleusTeq is your partner in empowering the Energy & Utilities sector, revolutionizing it to meet the demands of a changing world." />

      {/* <Accomplish
        squareImg={squarImg}
        accomplishText="OUR"
        accomplishText2=" ACCOMPLISHMENTS"
        accomplishText_fortune="Fortune 500 Clients"
      /> */}
      <OrangeBar />
      <OurSuccessStory
        successImg1={commImg}
        successHeading="OUR SUCCESS STORIES"
        successText="Realtime anomaly detection & network monitoring for major telecommunications company in USA."
      />
    </>
  );
}

export default Energy;
