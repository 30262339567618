import React from "react";
import { Row, Col } from "antd";
import "./capitalMarketCards.scss";
type Props = {
  dataTravel: {
    card1: string;
    comCardText1: string;
    CardText1: string;
  }[];
};

const CapitalMarketCards = ({ dataTravel }: Props) => {
  return (
    <Row justify={"center"} style={{ background: "rgb(29, 26, 54)", paddingTop:"2vw", paddingBottom: "2vw" }}>
      {dataTravel.map((data, i) => {
        return (
          <Col
            xs={24}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            xxl={10}
            className="capitalMarket_mainDiv"
            key={i}
          >
            <Row
              className="capitalMarket_bgImage"
              style={{
                background: `url("${data.card1}") no-repeat center center`,
                backgroundSize: "cover",
               
              }}
            >
              <Col span={12} className="capitalMarket_bgTop" />
              <Col className="capitalMarket_textcssView">
                <p className="capitalMarket_overlyText">{data.CardText1}</p>
              </Col>
            </Row>
            <div className="capitalMarket_comText" style={{ color: "#fff" }}>
              {data.comCardText1}{" "}
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default CapitalMarketCards;
