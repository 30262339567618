import React from "react";
import "./success.scss";
import { Carousel, Row, Col } from "antd";
import Success from "./Success";
import card1 from "../../assets/images/success_component/success_card1.webp";
import card2 from "../../assets/images/success_component/success_card2.webp";

function SuccessIndex() {
  const successStories = [
    {
      image: card1,
      text: "Real-time insights and recommendation engine for one of the largest pharmaceuticals company in the world.",
      link: "/overview?type=realtime",
    },
    {
      image: card2,
      text: "Journey to cloud: Big Data Lake on Azure for top pet retailer worldwide.",
      link: "/overview?type=bigdata",
    },
    {
      image: card2,
      text: "Back office RPA transformation for a major investment Bank.",
      link: "/overview?type=backOffice",
    },
  ];
  return (
    <div>
      <Row className="successMain">
        <Col span={22} xs={22} sm={22}>
          <h3 className="commonTitleHead">OUR SUCCESS STORIES</h3>
        </Col>
      </Row>

      <Carousel autoplay draggable dots={false}>
        {successStories.map((story, index) => (
          <div key={index}>
            <Success
              succesImage1={story.image}
              successText={story.text}
              link={story.link}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default SuccessIndex;
