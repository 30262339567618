import React from "react";
import { Row } from "antd";
import { CustomersAndPartners } from "../../Sections/HomePage/CustomerAndPartners";
import TermsBanner from "../../Components/TermsBanner/TermsBanner";
import TermsTab from "../../Components/TermsTab/TermsTab";
import bannerImage from "../../assets/images/terms/terms_banner.webp";

const Terms = () => {
  const tabData = [
    {
      title: "Acceptance of Terms",
      key: "1",
      content:
        "By accessing or using the Website, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms or the Privacy Policy, please do not use the Website.",
    },
    {
      title: "Changes to Terms",
      key: "2",
      content:
        "We reserve the right to modify or replace these Terms at any time without prior notice. Any changes will be effective immediately upon posting on this page. You are responsible for reviewing these Terms regularly.",
    },
    {
      title: "Use of the Website",
      key: "3",
      content:
        "You agree to use the Website for lawful purposes only. You must not use the Website in any way that may disrupt the functionality or accessibility of the Website or infringe on the rights of others.",
    },
    {
      title: "Intellectual Property",
      key: "4",
      content:
        "All content on the Website, including but not limited to text, graphics, logos, images, and software, is the property of NucleusTeq, NuoData and Fyndr and is protected by applicable intellectual property laws. You may not reproduce, modify, distribute, or republish any content from the Website without our prior written consent.",
    },
    {
      title: "Services and Products",
      key: "5",
      content:
        "Descriptions of our services and products provided on the Website are for informational purposes only and do not constitute binding offers. We reserve the right to modify or discontinue any service or product without prior notice.",
    },
    {
      title: "Disclaimer",
      key: "6",
      content:
        "The information provided on the Website is for general informational purposes only. We make no representations or warranties regarding the accuracy, completeness, or reliability of the information. Your use of the Website and reliance on any information is at your own risk.",
    },
    {
      title: "Limitation of Liability",
      key: "7",
      content:
        "In no event shall NucleusTeq, its affiliates, directors, officers, employees, or agents be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of the Website or the services or products offered.",
    },
    {
      title: "Indemnification",
      key: "8",
      content:
        "You agree to indemnify and hold harmless NucleusTeq and its affiliates, directors, officers, employees, and agents from and against any claims, liabilities, damages, losses, or expenses arising out of your use of the Website or any violation of these Terms.",
    },
    {
      title: "Governing Law",
      key: "9",
      content:
        "These Terms are governed by and construed in accordance with the laws of Your Jurisdiction. Any disputes arising from these Terms shall be subject to the exclusive jurisdiction of the courts in Your Jurisdiction.",
    },
    {
      title: "Contact Us",
      key: "10",
      content:
        "If you have any questions or concerns about these Terms or the Website, please contact us at ops@nucleusteq.com",
    },
  ];
  return (
    <>
      <div className="uiMain">
        <TermsBanner
          bannerText={"TERMS OF SERVICE"}
          bannerImage={bannerImage}
        />
        <Row justify={"center"} align={"middle"}>
          <TermsTab tabData={tabData} tabTitle="Terms of Service" />
          <CustomersAndPartners />
        </Row>
      </div>
    </>
  );
};

export default Terms;
