import React, { useEffect } from "react";
import { Layout, Image, Row } from "antd";
import "./home.scss";
import { Carousel } from "../../Components/Carousel";
import { homePageCarouselData } from "../../mocks/homepageCarousel";
import { Industries } from "../../Sections/HomePage/Industries";
import { CustomersAndPartners } from "../../Sections/HomePage/CustomerAndPartners";
import NewTitle from "../../Components/NewTitle/NewTitle";
import SuccessStoriesNew from "../../Components/SuccessStoriesNew/SuccessStoriesNew";
import GridView from "../../assets/images/industries/industries-grid.webp";
import { Services } from "../../Components/OurServices";
import { ProductIntroducton } from "../../Components/ProductIntroduction";
import ProductSection from "../../Sections/HomePage/ProductSection/ProductSection";
import { HomeBanner } from "../../Components/HomeBanner";
import SuccessStoriesScroll from "../../Components/SucessStoriesScroll/SucessStoriesScroll";

import bg from "../../assets/images/successStories1/bg.webp";
import sentiments from "../../assets/images/successStories1/sentiments.webp";
import realtime from "../../assets/images/successStories1/realtime.webp";
import backofficee from "../../assets/images/successStories1/backofficee.webp";
import liabilities from "../../assets/images/successStories1/liability.webp";
import real_time from "../../assets/images/successStories1/real-time.webp";
import modern from "../../assets/images/successStories1/modern.webp";
import digital from "../../assets/images/successStories1/digital.webp";
import lake from "../../assets/images/successStories1/lake.webp";
import enterprise from "../../assets/images/successStories1/enterprise.webp";
import detec from "../../assets/images/successStories1/detec.webp";

const Home: React.FC = () => {
  const servicedata = [
    {
      heading: "Enterprise Data Modernization",
      heading1:
        "Transform your legacy platforms into contemporary data platforms.",
      heading2: "",
      className1: "serviceContainer_servicesText",
      className2: "serviceContainer_servicesSubText",
      link: "/edm",
    },
    {
      heading: "Big-Data Advisory & Engineering",
      heading1:
        "Build an enterprise data lake to drive innovation and unlock big data potential",
      heading2: "",

      className1: "serviceContainer_servicesTextBigData",
      className2: "serviceContainer_servicesSubTextBigData",
      link: "/bigData",
    },
    {
      heading: "Analytics (AI, ML) Services",
      heading1:
        "Propel your business with cutting-edge AI and advanced analytics services ",
      heading2: "",
      className1: "serviceContainer_servicesTextAnalytics",
      className2: "serviceContainer_servicesSubTextAnalytics",
      link: "/analytics",
    },

    {
      heading: "Enterprise Cloud Solutions",
      heading1:
        "Boost business results, reduce operational costs, and maximize innovation with cloud adoption",
      heading2: "",
      className1: "serviceContainer_servicesTextCloud",
      className2: "serviceContainer_servicesSubTextCloud",
      link: "/cloud",
    },
    {
      heading: "Enterprise Automation",
      heading1:
        "Our RPA solutions empower enterprises to reduce costs dramatically and boost efficiency, productivity, and performance.",
      heading2: "",
      className1: "serviceContainer_servicesTextEnterprise",
      className2: "serviceContainer_servicesSubTextEnterprise",
      link: "/auto",
    },
    {
      heading: "CRM and ERP",
      heading1:
        "We develop CRM & ERP solutions that connect companies with their customers through intuitive interactions.",
      heading2: "",
      className1: "serviceContainer_servicesTextCRM",
      className2: "serviceContainer_servicesSubTextCRM",
      link: "/ui",
    },
  ];

  const sucessStories = [
    {
      src: real_time,
      info: "Real-time insights and recommendation engine for one of the largest pharmaceuticals company..",
      route: "/overview?type=realtime",
    },
    {
      src: bg,
      info: "Big-Data lake setup on Azure cloud for top 3 pet retailers in the world",
      route: "/overview?type=bigdata",
    },
    {
      src: sentiments,
      info: "Real-time insights and recommendation engine for one of the largest pharmaceuticals company..",
      route: "/overview?type=sentiment",
    },
    {
      src: modern,
      info: "Enterprise Data Modernization of one of world’s largest credit card company from Teradata to..",
      route: "/overview?type=modernization",
    },
    {
      src: detec,
      info: "Detection to Intent to Fraud using NPL for one of world’s largest banks",
      route: "/overview?type=detection",
    },
    {
      src: liabilities,
      info: "Modernization of Enterprise reporting (Netezza) to AWS for a major housing finance company",
      route: "/overview?type=newLiability",
    },
    {
      src: backofficee,
      info: "RPA Solution Streamlines Investment Firm.",
      route: "/overview?type=backOffice",
    },
    {
      src: digital,
      info: "Digital Transformation at a Top Oil & Gas Giant.",
      route: "/overview?type=digitalTransformation",
    },
    {
      src: realtime,
      info: "Real time Data Transformation for Top US Telecommunuications Giant",
      route: "/overview?type=realtimeAnomaly",
    },
    {
      src: lake,
      info: "End-to-End Data Lake Setup & Migration of Netezza reporting platform to Azure for a Major retailer",
      route: "/overview?type=datalake",
    },
    {
      src: enterprise,
      info: "Create new liability insurance models based on IOT sensor data for a leading business Insurer.",
      route: "/overview?type=enterprise",
    },
  ];
  useEffect(()=>{
      window.scrollTo(0,0)
  },[])
  return (
    <Layout style={{ background: "#1D1A36" }}>
      {/* <HomeBanner /> */}
      <Carousel data={homePageCarouselData} />
      <ProductSection />
      <ProductIntroducton />

      <Services servicedata={servicedata} />
      <Row className="grid-row" align={"middle"} justify={"center"}>
        <Industries />
        <h1 className="ocp-main-title" style={{ marginBottom: "0rem" }}>
          SUCCESS STORIES
        </h1>
        <div className="grid-div">
          <Image src={GridView} alt="GridView" preview={false} />
        </div>
      </Row>
      {/* <Row> */}
      {/* <SuccessStoriesNew /> */}
      <SuccessStoriesScroll
        sucessStories={sucessStories}
        onCardClick={(e) => {
          // console.log("oncard", e);
        }}
      />
      {/* </Row> */}
      <CustomersAndPartners />
    </Layout>
  );
};

export default Home;
