import HeroIndustries from "../../Components/HeroIndustries/HeroIndustries";
import OurPartners from "../../Components/OurPartners/OurPartners";
import ourPartnerHeroImg from "../../assets/images/OurPartners/ourPartnerHeroImg.webp";
import AAA from "../../assets/images/OurPartners/AAA.webp";
import alibaba from "../../assets/images/OurPartners/alibaba.webp";
import Hp from "../../assets/images/OurPartners/Hp.webp";
import aws from "../../assets/images/OurPartners/aws.webp";
import ServiceNow from "../../assets/images/OurPartners/ServiceNow.webp";
import uiPath from "../../assets/images/OurPartners/UiPath.webp";
import amazon from "../../assets/images/OurPartners/amazon.webp";
import azure from "../../assets/images/OurPartners/Azure.webp";
import peopleSoft from "../../assets/images/OurPartners/peopleSoft.webp";
import GCloud from "../../assets/images/OurPartners/GCloud.webp";
import Oracle from "../../assets/images/OurPartners/Oracle.webp";
import Salesforce from "../../assets/images/OurPartners/Salesforce.webp";
import mapr from "../../assets/images/OurPartners/mapr.webp";
import cloudera from "../../assets/images/OurPartners/cloudera.webp";
import Aiven from "../../assets/images/OurPartners/aiven.webp";
import deloitte from "../../assets/images/OurPartners/deloitte.webp";
import fivetran from "../../assets/images/OurPartners/fivetran.webp";
import snowflick from "../../assets/images/OurPartners/snowflick.webp";
import cisco from "../../assets/images/OurPartners/cisco.webp";
import IBM from "../../assets/images/OurPartners/IBM.webp";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";

import { Col, Row } from "antd";

// import cava from "../../assets/events_images/companies-icon/cava.webp";
// import dutchBrosCoffee from "../../../assets/events_images/companies-icon/dutchBros-coffee.webp";


const Partners = (Props: any) => {
  FunctionTitles("NucleusTeq | Partners");

  const data = [
    IBM,
    uiPath,
    aws,
    ServiceNow,
    Hp,
    alibaba,
    GCloud,
    Salesforce,
    amazon,
    AAA,
    Oracle,
    mapr,
    azure,
    peopleSoft,
    cloudera,
    cisco,
    Aiven,
    deloitte,
    fivetran,
    snowflick,
    // cava
  ];

  return (
    <div>
      <HeroIndustries
        pageName=" OVERVIEW /  OUR PARTNERS"
        image={ourPartnerHeroImg}
        pageIntro="OUR PARTNERS"
        alt="NucleusTeq"
        pageIntroPara={"Building a Stronger Future, Together"}
      />

      <OurPartners
        data={data}
        title="OUR PARTNERS"
        subTitle="At NucleusTeq, we believe that strategic partnerships are a
          cornerstone of innovation and growth, enabling us to deliver even
          greater value to our clients and the broader business community."
      />
    </div>
  );
};
export default Partners;
