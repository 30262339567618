import { useEffect } from "react";

const HotjarTracking = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      (function (h: any, o: any, t: any, j: string, a: any, r: any) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 5304632, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(
        window,
        document,
        "https://static.hotjar.com/c/hotjar-",
        ".js?sv=",
        null,
        null
      );
    }
  }, []);

  return null;
};

export default HotjarTracking;
