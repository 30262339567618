import icon1 from "../assets/images/careerPage/Career Growth.webp";
import icon2 from "../assets/images/careerPage/Work-Life Growth.webp";
import icon3 from "../assets/images/careerPage/Health & Insurance Benefits.webp";
import icon4 from "../assets/images/careerPage/Equality.webp";
import icon5 from "../assets/images/careerPage/Competitive Compensation.webp";
import icon6 from "../assets/images/careerPage/Learn & Grow with Ownership.webp";
import icon7 from "../assets/images/careerPage/icon1.webp";
import icon8 from "../assets/images/careerPage/icon2.webp";
import icon9 from "../assets/images/careerPage/icon3.webp";
import icon10 from "../assets/images/careerPage/icon4.webp";
import icon11 from "../assets/images/careerPage/icon5.webp";
import icon12 from "../assets/images/careerPage/icon6.webp";

export const benefitCards = [
  {
    img: icon1,
    heading1: "Career Growth",
    benefitText: "Explore innovative career tracks and grow with the company.",
  },
  {
    img: icon2,
    heading1: "Work-Life Growth",
    benefitText:
      "Enjoy flexibility with parental leave, work hours, and remote work options.",
  },
  {
    img: icon3,
    heading1: "Health & Insurance Benefits",
    benefitText:
      "Protect what matters most with comprehensive medical insurance and company-paid life insurance.",
  },
  {
    img: icon4,
    heading1: "Equality",
    benefitText:
      "We're committed to creating an inclusive environment where everyone can thrive.",
  },
  {
    img: icon5,
    heading1: "Competitive Compensation",
    benefitText:
      " Plan for your future with performance bonuses and competitive compensation packages.",
  },
  {
    img: icon6,
    heading1: "Learn & Grow with Ownership",
    benefitText:
      " Dive into challenging projects and embrace a culture of continuous learning and exploration.",
  },
];

export const benefitCards2 = [
  {
    img: icon7,
    heading1: "Integrity & Honesty",
    benefitText:
      " Upholding ethical standards, honesty, and trustworthiness in all we do.",
  },
  {
    img: icon8,
    heading1: " Commitment to Client Value",
    benefitText:
      "Delivering value consistently and building long-lasting client relationships.",
  },
  {
    img: icon9,
    heading1: "Compassion & Respect for Individuals",
    benefitText:
      " Fostering diversity, inclusivity, and a respectful work environment.",
  },
  {
    img: icon10,
    heading1: "Dependability",
    benefitText: "Ensuring timely, high-quality customer deliveries.",
  },
  {
    img: icon11,
    heading1: "Stewardship",
    benefitText:
      "Building a better future for all, while protecting our brand.",
  },
  {
    img: icon12,
    heading1: "Employee First Culture",
    benefitText:
      'Attracting, developing, and retaining top talent, fostering a supportive environment, and nurturing a "can-do" attitude.',
  },
];
