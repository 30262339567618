import icon1 from "../assets/images/careerPage/peoples_Image1.webp";
import icon2 from "../assets/images/careerPage/peoples_Image2.webp";
import icon3 from "../assets/images/careerPage/peoples_Image3.webp";
import icon4 from "../assets/images/careerPage/peoples_Image4.webp";
import icon5 from "../assets/images/careerPage/peoples_Image5.webp";
import icon6 from "../assets/images/careerPage/peoples_Image6.webp";

const imagesP = [
  {
    img: icon1,
  },
  {
    img: icon2,
  },
  {
    img: icon3,
  },
  {
    img: icon4,
  },
  {
    img: icon5,
  },
  {
    img: icon6,
  },
];

export { imagesP };
