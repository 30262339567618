import React from "react";
import "../../../../src/main.scss";
import { Layout } from "antd";
import { Route, Routes } from "react-router-dom";

// import { SuccessStories } from "../../../Sections/HomePage/SuccessStories";
import News from "../../../Pages/News";
import Portal from "../../../Pages/Portal";
import Contact from "../../../Pages/Contact";
import CareerPage from "../../../Pages/CareerPage";
import EDM from "../../../Pages/EDM";
import Cloud from "../../../Pages/Cloud";
import BigData from "../../../Pages/BigData";
import UI from "../../../Pages/UI";
import Analytics from "../../../Pages/Analytics";
import Automation from "../../../Pages/Automation";
import Home from "../../../Pages/Home";
import Communication from "../../../Pages/Communication";
import Insurance from "../../../Pages/Insurance";
import Life from "../../../Pages/LifeScience";
import Retail from "../../../Pages/RetailPage";
import Bank from "../../../Pages/Banking";
import Energy from "../../../Pages/Energy";
import Travel from "../../../Pages/Travel";
import Customers from "../../../Pages/Customers";
import Partners from "../../../Pages/Partners";
import Leadership from "../../../Pages/Leadership";
import AboutUs from "../../../Pages/AboutUs";
import SuccessStory from "../../../Pages/SuccessStory";
import OverviewSuccess from "../../../Pages/OverviewSucess";
import DataGov from "../../../Pages/DataGov";
import Terms from "../../../Pages/Terms";
import FyndrProduct from "../../../Pages/FyndrProduct";
import NuoDataProduct from "../../../Pages/NuoDataProduct";
import FAQ_Product from "../../../Pages/FAQ_Product";
import Policy from "../../../Pages/Policy";
import Privacy from "../../../Pages/Privacy";
import Blog from "../../../Pages/Blog";
import BlogDetails from "../../../Pages/BlogDetails";
import BlogEdit from "../../../Pages/BlogEdit";
import Events from "../../Events";
import Event from "../../../Pages/Event";
//this file will include all the routes of the project

const AppRoutes = () => {
  const { Content } = Layout;
  return (
    <Content>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogEdit" element={<BlogEdit />} />
        <Route path="/blogDetails" element={<BlogDetails />} />

        <Route path="/news" element={<News />} />
        <Route path="/portal" element={<Portal />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/careerPage" element={<CareerPage />} />

        <Route path="/edm" element={<EDM />} />
        <Route path="/cloud" element={<Cloud />} />
        <Route path="/bigdata" element={<BigData />} />
        <Route path="/ui" element={<UI />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/auto" element={<Automation />} />
        <Route path="/data-governance" element={<DataGov />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/fyndr" element={<FyndrProduct />} />
        <Route path="/nuodata" element={<NuoDataProduct />} />

        <Route path="/bank" element={<Bank />} />
        <Route path="/retail" element={<Retail />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/life" element={<Life />} />
        <Route path="/communication" element={<Communication />} />
        <Route path="/energy" element={<Energy />} />
        <Route path="/travel" element={<Travel />} />
        <Route path="faq" element={<FAQ_Product />} />

        <Route path="/customers" element={<Customers />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/leadership" element={<Leadership />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/successStories" element={<SuccessStory />} />
        <Route path="/overview" element={<OverviewSuccess />} />

        <Route path="/events" element={<Events />} />
        <Route path="/event" element={<Event />} />
      </Routes>
    </Content>
  );
};
export default AppRoutes;
