import React from "react";
import { Row, Col } from "antd";
import "./blog.scss";
import BannerBg from "../../assets/images/blog/banner.webp";
import { ProductBanner } from "../../Components/ProductBanner";
import retail_Card_img1 from "../../assets/images/blog/blog_Card_img1.webp";
import retail_Card_img2 from "../../assets/images/blog/blog_Card_img2.webp";
import retail_Card_img3 from "../../assets/images/blog/blog_Card_img3.webp";
import retail_Card_img4 from "../../assets/images/blog/blog_Card_img4.webp";
import retail_Card_img5 from "../../assets/images/blog/blog_Card_img5.webp";
import retail_Card_img6 from "../../assets/images/blog/blog_Card_img6.webp";
import retail_Card_img7 from "../../assets/images/blog/blog_Card_img7.webp";
import retail_Card_img8 from "../../assets/images/blog/blog_Card_img8.webp";
import retail_Card_img9 from "../../assets/images/blog/blog_Card_img9.webp";
import BlogCards from "../../Components/BlogCards/BlogCards";
const Blog = (props: any) => {
  const dataCom = [
    {
      blogCardImg1: retail_Card_img1,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img2,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img3,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img4,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img5,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img6,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img7,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img8,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img9,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
  ];
  return (
    <>
      <div className="uiMain">
        <ProductBanner
          title="ADVANTAGES OF IMPLEMENTING RPA FOR CONTACT CENTERS"
          link=""
          productBanner={BannerBg}
          title2=""
          btnTitle="Read More"
          btnTitle2=""
        />
        <Row style={{ width: "100%", textAlign: "center" }} justify={"center"}>
          <Col md={19} xs={22} sm={22} className="commonTitleHead">
            Advantages of Implementing RPA for Contact Centers
          </Col>
          <Col md={19} xs={22} sm={22} className="headingParagraph">
            Welcome to our Knowledge Hub, where expertise meets innovation.
            We're dedicated to sharing insights, exploring cutting-edge trends,
            and providing top-notch information to empower you on your journey.
            Dive into our insights to discover the latest in data tranformation,
            data modernization and management, Big Data, Mobility, Analytics and
            Ai, Automation, CRM and ERP and more..
          </Col>
          <Col
            md={19}
            xs={22}
            sm={22}
            className="bdBlueOrangeGrad"
            style={{ marginTop: "8vh" }}
          >
            <span className="BlogCardDiv">
              <BlogCards blogdata={dataCom} />
            </span>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Blog;
