import React from "react";
import { Layout } from "antd";
import AppRoutes from "./Content";
import "../../main.scss";
import { useLocation } from "react-router-dom";

import Header from "./Header/Header";
import { Footer } from "./Footer";
import { Social } from "./Social";
import LetConnect from "../LetConnect/LetConnect";
import HotjarTracking from "./HotjarTracking";
const AppLayout = (props: any) => {
  const location = useLocation();
  const hideHeaderFooter =
    location.pathname === "/events/" || location.pathname === "/events";

  return (
    <>
      <HotjarTracking />
      <Layout style={{ background: "#1d1a36" }}>
        {!hideHeaderFooter && <Header />}
        <AppRoutes />
        {!hideHeaderFooter && (
          <>
            <LetConnect padding={true} />
            <Footer />
            <Social />
          </>
        )}
      </Layout>
    </>
  );
};
export default AppLayout;
