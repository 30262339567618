"use client";

import { Col, Row } from "antd";
import React from "react";
import aws from "../../../assets/events_images/companies-icon/aws.webp";
import aaa from "../../../assets/events_images/companies-icon/aaa.webp";
import ibm from "../../../assets/events_images/companies-icon/ibm.webp";
import databricks from "../../../assets/events_images/companies-icon/databricks.webp";

import amex from "../../../assets/events_images/companies-icon/amex.webp";
import citizensBank from "../../../assets/events_images/companies-icon/citizens-bank.webp";
import cava from "../../../assets/events_images/companies-icon/cava.webp";
import mckesson from "../../../assets/events_images/companies-icon/mckesson.webp";
import republicServices from "../../../assets/events_images/companies-icon/republic-services.webp";
import jeniusBank from "../../../assets/events_images/companies-icon/jeniusBank.webp";
import mmc from "../../../assets/events_images/companies-icon/mmc.webp";
import goDaddy from "../../../assets/events_images/companies-icon/go-daddy.webp";
import bannerHealth from "../../../assets/events_images/companies-icon/banner-health.webp";
import blueCross from "../../../assets/events_images/companies-icon/blue-cross.webp";
import sprouts from "../../../assets/events_images/companies-icon/sprouts.webp";
import discountTire from "../../../assets/events_images/companies-icon/discount-tire.webp";
import diamondBacks from "../../../assets/events_images/companies-icon/diamond-backs.webp";
import petsmart from "../../../assets/events_images/companies-icon/petsmart.webp";
import asu from "../../../assets/events_images/companies-icon/asu.webp";
import circleK from "../../../assets/events_images/companies-icon/circle-k.webp";
import wellsFargo from "../../../assets/events_images/companies-icon/wells-fargo.webp";
import albertsons from "../../../assets/events_images/companies-icon/albertsons.webp";
import me from "../../../assets/events_images/companies-icon/me.webp";
import salesforce from "../../../assets/events_images/companies-icon/salesforce.webp";
import comericabanklogo from "../../../assets/events_images/companies-icon/comerica-bank.webp";
import gaiglogo from "../../../assets/events_images/companies-icon/gaig.webp";
import nucleusteq from "../../../assets/events_images/companies-icon/nucleusteq.webp";
import nuoData from "../../../assets/events_images/companies-icon/nuo-data.webp";
import choiceHotel from "../../../assets/events_images/companies-icon/choice_hotels.webp";
import JPMorgan from "../../../assets/events_images/companies-icon/jp-morgan.webp";
import mufg from "../../../assets/events_images/companies-icon/MUFG.webp";
import dutchBrosCoffee from "../../../assets/events_images/companies-icon/dutchBros-coffee.webp";
import cvs from "../../../assets/events_images/companies-icon/CVS.webp";

import BNY_MELLON from "../../../assets/events_images/companies-icon/BNY_MELLON.webp";
import Carvana from "../../../assets/events_images/companies-icon/Carvana.webp";
import City_of_Scottsdale from "../../../assets/events_images/companies-icon/City_of_Scottsdale.webp";
import Discover from "../../../assets/events_images/companies-icon/Discover.webp";
import GCP from "../../../assets/events_images/companies-icon/GCP.webp";
import Life_Stance_Health from "../../../assets/events_images/companies-icon/Life_Stance_Health.webp";
import Myriad_Genetics from "../../../assets/events_images/companies-icon/Myriad_Genetics.webp";
import Onsemi from "../../../assets/events_images/companies-icon/Onsemi.webp";
import True_Nolen_Pest_Control_Termites from "../../../assets/events_images/companies-icon/True_Nolen_Pest_Control_Termites.webp";
import CBTS from "../../../assets/events_images/companies-icon/CBTS.webp";

const Partner: React.FC = () => {
  const companies = [
    aws,
    aaa,
    ibm,
    amex,
    citizensBank,
    cava,
    mckesson,
    republicServices,
    jeniusBank,
    mmc,
    goDaddy,
    bannerHealth,
    blueCross,
    // sprouts,
    discountTire,
    diamondBacks,
    petsmart,
    asu,
    circleK,
    wellsFargo,
    albertsons,
    // me,
    salesforce,
    comericabanklogo,
    gaiglogo,
    choiceHotel,
    JPMorgan,
    mufg,
    dutchBrosCoffee,
    cvs,
    BNY_MELLON,
    Carvana,
    City_of_Scottsdale,
    Discover,
    GCP,
    Life_Stance_Health,
    Myriad_Genetics,
    Onsemi,
    True_Nolen_Pest_Control_Termites,
  ];

  const sponsors = {
    diamond: [
      { image: nucleusteq, link: "https://nucleusteq.com/" },
      { image: nuoData, link: "https://nuodata.io/" },
    ],
    platinum: [{ image: CBTS, link: "https://www.cbts.com/" }],
    gold: [{ image: aws }],
    silver: [
      { image: aws, link: "https://aws.amazon.com/" },
      { image: GCP, link: "https://cloud.google.com/" },
      { image: databricks, link: "https://databricks.com/" },
      { image: ibm, link: "https://www.ibm.com/" },
    ],
  };

  const SponsorSection = ({ title, className, sponsors }: any) => (
    <>
      <p className={className}>
        {title} Sponsors <br />
      </p>
      <Row
        style={{
          width: "100%",
          marginBottom: title === "Silver" ? "6vw" : 0,
        }}
        justify="center"
      >
        {sponsors.map((e: any, i: any) => (
          <Col
            key={i}
            className="partners-col"
            onClick={() => {
              e?.link && window.open(e.link, "_blank");
            }}
            style={{cursor : "pointer"}}
          >
            <img
              src={e?.image}
              className="partners-img"
              alt={`${title} Sponsor`}
              loading="lazy"
            />
          </Col>
        ))}
      </Row>
    </>
  );

  return (
    <>
      <div className="partner-area ptb-120">
        <div className="container">
          <div className="partner-section-title">
            <h2>Attending</h2>
            <h1>Companies</h1>
          </div>
          <Row style={{ width: "100%" }} justify={"center"}>
            {companies.map((e, i) => {
              return (
                <>
                  <Col key={i} className="partners-col">
                    <img
                      src={e}
                      className="partners-img"
                      alt="NucleusTeq"
                      loading="lazy"
                    />
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      </div>
      <div className="partner-area ptb-120 pt-0">
        <div className="container">
          <div className="partner-section-title">
            <h1>Sponsors</h1>
          </div>
          <Row style={{ width: "100%" }} justify={"center"}>
            <Col span={22}>
              <SponsorSection
                title="Diamond"
                className="diamond-sponsors"
                sponsors={sponsors.diamond}
              />
              <SponsorSection
                title="Platinum"
                className="platinum-sponsors"
                sponsors={sponsors.platinum}
              />
              {/* <SponsorSection
                title="Gold"
                className="gold-sponsors"
                sponsors={sponsors.gold}
              /> */}
              <SponsorSection
                title="Silver"
                className="silver-sponsors"
                sponsors={sponsors.silver}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Partner;
