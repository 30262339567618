import { Row, Col, Space, Form, Input, List, Image, Button } from "antd";
import insta from "../../../assets/images/footer/footer_insta.webp";
import linkedin from "../../../assets/images/footer/footer_linkedin.webp";
import YouTube from "../../../assets/images/footer/youtube.webp";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import "./social.scss";

const Social = () => {
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    // console.log("Form Submitted:", values);
    navigate("/");
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="social">
      <Row justify={"center"} className="social_row1">
        {/* <Col
          xs={24}
          sm={16}
          md={22}
          lg={21}
          xl={9}
          className="social_connected"
        >
          Stay connect with anyone
          <br />& anywhere
        </Col> */}
        <Col xs={24} sm={16} md={22} lg={6} xl={6}>
          <List>
            <Col className="social_headings">Connect with Us on Social</Col>
            <Col className="social_address">
              <Space direction="horizontal" size="middle" align="center">
                <a
                  href="https://www.youtube.com/channel/UCs1YfOQVmbmPdiD0CVwPHKw/channels"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Image
                    src={YouTube}
                    preview={false}
                    alt="YouTube"
                    className="footer_icon"
                    loading="lazy"
                  />
                </a>
                <a href="/" rel="noopener noreferrer" target="_blank">
                  <Image
                    src={insta}
                    preview={false}
                    alt="facebook"
                    className="footer_icon"
                    loading="lazy"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/nucleusteq/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Image
                    src={linkedin}
                    preview={false}
                    alt="facebook"
                    className="footer_icon"
                    loading="lazy"
                  />
                </a>
              </Space>
            </Col>
          </List>
        </Col>
        <Col xs={0} sm={0} md={0} lg={2} xl={2} />
        <Col xs={24} sm={16} md={22} lg={16} xl={16}>
          <Col className="social_headings">Stay Ahead of the Curve!</Col>
          <Col className="social_insights">
            Join our newsletter today to unlock the hottest trends and
            cutting-edge innovations in the world of data and digital
            transformation. Don't miss out on exclusive content, expert
            analyses, and actionable tips to supercharge your digital journey.
            Ready to lead in the data-driven era? Sign up now and be at the
            forefront of innovation!
          </Col>
          <Form
            name="newsletter"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row justify={"start"}>
              <Col
                xs={24}
                sm={22}
                md={10}
                xxl={22}
                lg={10}
                xl={10}
                style={{ marginRight: "20px" }}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email Address!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid Email Address!",
                    },
                  ]}
                >
                  <Input
                    className="social_emailInput"
                    placeholder="Enter e-mail"
                    variant="borderless"
                    name="Email Address"
                  />
                </Form.Item>
              </Col>
              <Col xs={20} sm={22} md={10} lg={12} xxl={10}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="social_submitBtn"
                  >
                    <span>
                      Submit <BsArrowRight className="Arrow" />
                    </span>
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <div className="social_divider" />
      <Row className="social_cpRow">
        <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10}>
          <p className="social_rightsReserved">
            ©{new Date().getFullYear()} NucleusTeq Inc. All Rights Reserved.
          </p>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={14}
          xxl={14}
          className="social_termPrivacyCol"
        >
          <p>
            <Link
              to="/terms"
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <span className="social_termService">TERMS OF SERVICE</span>
              &nbsp;
            </Link>
            <Link
              to={"/policy"}
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              &nbsp; <span className="social_termService">PRIVACY POLICY</span>
            </Link>
          </p>
        </Col>
      </Row>
    </div>
  );
};
export default Social;
