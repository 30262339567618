import React from "react";
import "./solution.scss";
import { Col, Row, Image, Space } from "antd";
import arrowUp from "../../assets/images/edm/edm_arrowUp.webp";

const SolutionImage = ({ images }: { images: any[] }) => (
  <Row gutter={[16, 16]} justify="center" align="middle">
    {images.map((image: any, index: any) => (
      <Col key={index} className="solutionMainDiv_imgCol">
        <Image
          src={image}
          preview={false}
          alt={`solImg${index}`}
          className="solutionMainDiv_image"
        />
      </Col>
    ))}
  </Row>
);

const Solution = () => {
  const solImages1 = Array.from({ length: 5 }, (_, index) =>
    require(`../../assets/images/edm/edm_solutions${index + 1}.webp`)
  );

  const solImages2 = Array.from({ length: 5 }, (_, index) =>
    require(`../../assets/images/edm/edm_solutions${index + 6}.webp`)
  );

  const sectionContent = [
    "Reporting Layer",
    "Application Layer",
    "Analytics Layer",
  ];

  const sectionContent2 = [
    "Single Version of Truth Data Models",
    "MetaData Mapping POA vs POD",
    "Query Conversion & Performance Tuning",
  ];

  const sectionContent3 = [
    "Stewardship/ Policies",
    "Lineage",
    "Service Catalog",
    "Audit/ Monitoring",
    "Workflow/DQ",
    "MetaData MGT",
  ];

  return (
    <div className="solutionDiv">
      <div className="solutionGradDiv" />
      <div>
        <Row className="solutionMain commonTitleHead">
          SOLUTION ARCHITECTURE
        </Row>
        <Row className="solutionMain">
          <Col
            xs={22}
            sm={22}
            md={22}
            lg={18}
            xl={18}
            xxl={18}
            className="solutionMainDiv"
          >
            <Space direction="vertical" size="large">
              <SolutionImage images={solImages1} />
              <Row
                gutter={[24, 24]}
                className="solutionMainDiv_secRow"
                justify="center"
              >
                <SolutionImage images={solImages2} />
              </Row>

              <Row
                gutter={[24, 24]}
                justify="center"
                align="middle"
                className="solutionThree"
              >
                {sectionContent.map((content, index) => (
                  <Col
                    key={index}
                    xs={24}
                    sm={24}
                    md={8}
                    className="solutionMain"
                  >
                    <div className="solutionMainDiv_blueDiv">
                      <span>{content}</span>
                    </div>
                  </Col>
                ))}
              </Row>

              <Row
                gutter={[24, 24]}
                justify="center"
                align="middle"
                className="solutionFour"
              >
                {sectionContent2.map((content, index) => (
                  <Col
                    key={index}
                    xs={24}
                    sm={24}
                    md={8}
                    className="solutionMain"
                  >
                    <div className="solutionMainDiv_blueDiv">
                      <span>{content}</span>
                    </div>
                  </Col>
                ))}
              </Row>

              <Row gutter={[24, 24]} justify="center" align="middle">
                <div className="solutionMainDiv_blueDiv solutionFiveSix">
                  <span>Refined Layer</span>
                </div>
              </Row>

              <Row gutter={[24, 24]} justify="center" align="middle">
                <div className="solutionMainDiv_blueDiv solutionFiveSix">
                  <span>Raw Layer</span>
                </div>
              </Row>

              <Row gutter={[24, 24]} justify="center" align="middle">
                <div
                  className="solutionMainDiv_blueDiv"
                  style={{ width: "100%" }}
                >
                  <span style={{ padding: "1.9vh 1.5vw" }}>
                    <Row gutter={[24, 24]} justify="center" align="middle">
                      {sectionContent3.map((content, index) => (
                        <Col
                          key={index}
                          xs={24}
                          sm={24}
                          md={12}
                          lg={4}
                          className="solutionMain"
                        >
                          <div
                            className="solutionMainDiv_blueDiv"
                            style={{ height: "9vh" }}
                          >
                            <span>{content}</span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </span>
                </div>
              </Row>
              <Row gutter={[24, 24]} justify="center" align="middle">
                <div className="solutionLastDiv">
                  <Row gutter={[24, 24]} justify="center" align="middle">
                    <div className="solutionMainDiv_blueDiv solutionLast">
                      <span>Data Ingestion to Cloud or on-prem</span>
                    </div>
                  </Row>
                  <Row gutter={[24, 24]} justify="center" align="middle">
                    {[1, 2, 3].map((index) => (
                      <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={8}
                        className="solutionMain"
                      >
                        <Space
                          direction="vertical"
                          className="solutionMain"
                          style={{ width: "100%" }}
                        >
                          {index === 1 ? (
                            <div className="solutionMain">
                              <Image
                                src={arrowUp}
                                preview={false}
                                alt="UP"
                                className="solutionLast_image"
                              />
                            </div>
                          ) : (
                            <div className="solutionMain solutionLast_mobImage">
                              <Image
                                src={arrowUp}
                                preview={false}
                                alt="UP"
                                className="solutionLast_image"
                              />
                            </div>
                          )}

                          {index === 3 ? (
                            <div className="solutionMainDiv_blueDiv solutionLast">
                              <span>SOR..n</span>
                            </div>
                          ) : (
                            <div className="solutionMainDiv_blueDiv solutionLast">
                              <span>SOR{index}</span>
                            </div>
                          )}
                        </Space>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Row>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Solution;
