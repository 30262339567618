import React from "react";
import GridBox from "../GridBox/GridBox";
import AnalyticBox1 from "../../assets/images/analytic/analytic_box1.webp";
import AnalyticBox2 from "../../assets/images/analytic/analytic_box2.webp";
import AnalyticBox3 from "../../assets/images/analytic/analytic_box3.webp";
import AnalyticBox4 from "../../assets/images/analytic/analytic_box4.webp";
import AnalyticBox5 from "../../assets/images/analytic/analytic_box5.webp";
import AnalyticBox6 from "../../assets/images/analytic/analytic_box6.webp";
import AnalyticBox7 from "../../assets/images/analytic/analytic_box7.webp";
import AnalyticBox8 from "../../assets/images/analytic/analytic_box8.webp";
import AnalyticBox9 from "../../assets/images/analytic/analytic_box9.webp";
import { Col, Row } from "antd";

const analyticsData = [
  {
    image: AnalyticBox1,
    text: "Recommendation Systems",
  },
  {
    image: AnalyticBox2,
    text: "Anomaly Detection",
  },
  {
    image: AnalyticBox3,
    text: "Supply Chain Predictions",
  },
  {
    image: AnalyticBox4,
    text: "Operations Optimization",
  },
  {
    image: AnalyticBox5,
    text: "Deep Learning",
  },
  {
    image: AnalyticBox6,
    text: "Real-Time Fraud Detection",
  },
  {
    image: AnalyticBox7,
    text: "Text Mining",
  },
  {
    image: AnalyticBox8,
    text: "Network Monitoring",
  },
  {
    image: AnalyticBox9,
    text: "Graph Algorithms",
  },
];

function AnalyticsGridBox() {
  return (
    <Row className="AnalyticsGridBoxDiv">
      {analyticsData.map((data, index) => (
        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}
        key={index}>
          <GridBox
            key={index}
            gridBoxData={[
              {
                image: data.image,
                text: data.text,
              },
            ]}
          />
        </Col>
      ))}
    </Row>
  );
}

export default AnalyticsGridBox;
