import fyndricon1 from "../assets/images/product/fyndr-product/fyndricon1.webp"
import fyndricon2 from "../assets/images/product/fyndr-product/fyndricon2.webp"
import fyndricon3 from "../assets/images/product/fyndr-product/fyndricon3.webp"
import fyndricon4 from "../assets/images/product/fyndr-product/fyndricon4.webp"
import fyndricon5 from "../assets/images/product/fyndr-product/fyndricon5.webp"
import fyndricon6 from "../assets/images/product/fyndr-product/fyndricon6.webp"
import fyndricon7 from "../assets/images/product/fyndr-product/fyndricon7.webp"
import fyndricon8 from "../assets/images/product/fyndr-product/fyndricon8.webp"
import fyndricon9 from "../assets/images/product/fyndr-product/fyndricon9.webp"
import fyndricon10 from "../assets/images/product/fyndr-product/fyndricon10.webp"



export const fyndrAdvantage = [
    {
        img: fyndricon1,
        heading1: "Online Stores",
        benefitText: "Create stunning online storefronts effortlessly and watch your sales skyrocket.",
    },
    {
        img: fyndricon2,
        heading1: "Offers & Events",
        benefitText:
            "Craft irresistible deals and events, and let Fyndr's platform handle the promotion.",
    },
    {
        img: fyndricon3,
        heading1: "Targeted Promotions",
        benefitText:
            "Reach your ideal customers precisely when and where it matters most.",
    },
    {
        img: fyndricon4,
        heading1: "Contactless Payments",
        benefitText:
            "Seamlessly manage payments, invoices, and orders, all in one place.",
    }
];
export const fyndrAdvantage2 = [
    {
        img: fyndricon5,
        heading1: "Cost-Effective ",
        benefitText: "Enjoy FREE access with transparent, low fees (just 5%).",
    },
    {
        img: fyndricon6,
        heading1: "Engage & Stand Out",
        benefitText:
            "Promote your business effectively with personalized pages and targeted campaigns.",
    },
    {
        img: fyndricon7,
        heading1: "Data-Driven Insights",
        benefitText:
            "Say goodbye to guesswork make decisions backed by data.",
    },
    {
        img: fyndricon8,
        heading1: "Loyalty & Results",
        benefitText:
            "Build lasting customer relationships and boost campaign performance.",
    },
    {
        img: fyndricon9,
        heading1: "Efficient Operations",
        benefitText:
            "Say goodbye to guesswork; make decisions backed by data.",
    },
    {
        img: fyndricon10,
        heading1: "Scalability & Expansion",
        benefitText:
            "Build lasting customer relationships and boost campaign performance.",
    }
];