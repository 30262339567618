"use client";

import React from "react";
import { Link } from "react-router-dom";
import { Col, Image, Row } from "antd";
import venueLogo from "../../assets/events_images/venue-logo.webp";

const Footer: React.FC = () => {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <footer className="footer-area">
        <div className="container">
          <Row justify={"center"} style={{ width: "100%" }}>
            <Col>
              <div className="single-footer-widget">
                <h3>Venue & Accommodations</h3>
                <div className="footer-venue-logo">
                  <Image preview={false} src={venueLogo} alt="venue-logo" />
                </div>
                <p>5350 E. Marriott Drive, Phoenix, AZ 85054</p>
                <p>Contact JW Marriott Desert Ridge</p>
                <p>to book a room at a preferred rate.</p>

                <ul className="social-links">
                  <li>
                    <a
                      href="https://www.facebook.com/nucleusteq"
                      className="facebook"
                      target="_blank"
                    >
                      <i className="icofont-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/nucleusteq/"
                      className="linkedin"
                      target="_blank"
                    >
                      <i className="icofont-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/nucleusteq/"
                      className="instagram"
                      target="_blank"
                    >
                      <i className="icofont-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>

        {/* <div className="col-lg-6 col-md-6">
              <div className="single-footer-widget">
                <h3>Parking </h3>
                <p>
                  Valet and gated self-parking are available at a discounted
                  rate for Summit attendees.
                </p>
                <h3>Accommodations</h3>
                <p>
                  Valet and gated self-parking are available at a discounted
                  rate for Summit attendees.
                </p>
              </div>
            </div> */}
        <div className="copyright-area-bg" />

        <div
          className="col-lg-12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="copyright-area">
            <ul>
              <li>
                <Link to="/contact" onClick={handleLinkClick}>
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/events/#registerSection" onClick={handleLinkClick}>
                  Registration
                </Link>
              </li>
              <li>
                <Link to="/policy" onClick={handleLinkClick}>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms" onClick={handleLinkClick}>
                  Terms & Conditions
                </Link>
              </li>
            </ul>

            <p>©Copyright 2024- NucleusTeq. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
