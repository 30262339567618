import React from "react";
import { Col, Row, Space } from "antd";
import leftLiftShift from "../../assets/images/edm/edm_liftShiftLeft.webp";
import rightLiftShift from "../../assets/images/edm/edm_liftShiftRight.webp";
import "./liftShift.scss";
import "@fontsource/indie-flower";

interface Props {
  lsHead1: string;
  lsContent: string;
}

const LiftShift = ({ lsHead1, lsContent }: Props) => {
  return (
    <div>
      <Row className="liftShiftRowMain" justify={"center"} align={"middle"}>
        <Row className="liftShiftHeadRow">
          <h2 className="commonTitleHead">{lsHead1}</h2>
        </Row>
        <Col md={22} sm={22} xs={22}>
          <Row className="liftShiftTextRow">
            <Col>
              <h1 className="liftShiftText">“Lift & Shift”</h1>
            </Col>
            <Col>
              <h2 className="headingTextMain lsSecHead">AGAIN</h2>
            </Col>
          </Row>
        </Col>
        <Col xl={16} lg={16} md={16} sm={22} xs={22}>
          <h5 className="paragraphText lsContent">{lsContent}</h5>
        </Col>

        {/* <Col xl={20} lg={20} md={20} sm={0} xs={0}>
          <iframe
            title="bgImage"
            src={"/all-graph/service/edm/data.html"}
            className="liftShiftIframe"
          />
        </Col> */}

        {/* <Col xl={0} lg={0} md={22} sm={22} xs={22}>
          <iframe
            title="bgImage"
            src={"https://nucleusteq.com/all-graph/service/mobile_view/one/demo/one.html"}
            className="liftShiftIframeMob"
          />
        </Col> */}
      </Row>
    </div>
  );
};

export default LiftShift;
