"use client";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tabs, Image } from "antd";
import aboutUsImg from "../../assets/events_images/about-img2.webp";

const AboutContent: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("1");

  const handleTabChange = (key: string) => {
    setSelectedTab(key);
  };

  const tabItems = [
    {
      key: "1",
      label: "Information",
      children: (
        <div>
          Engage with top industry leaders and explore cutting-edge advancements
          in data, cloud, and AI/ML through exciting keynotes, lively
          discussions, and immersive sessions. Discover how Fortune 500 peers
          are tackling today’s biggest challenges to build agile, compliant data
          ecosystems. Get a first look at emerging technologies and embark on a
          journey of discovery, innovation, and transformation. Don’t miss this
          unique opportunity to shape tomorrow’s data universe, today.
        </div>
      ),
    },
    {
      key: "2",
      label: "Topics",
      children: (
        <div>
          Participate in lively discussions on:
          <ul>
            <li>Data transformation in multi-cloud environments</li>
            <li>Future trends in data modernization & management</li>
            <li>AI: Opportunities, risks, and challenges</li>
            <li>Essential elements of a robust Data & Analytics platform</li>
            <li>Driving business value through cloud resilience</li>
            <li>AI governance, quality, and privacy</li>
          </ul>
        </div>
      ),
    },
    {
      key: "3",
      label: "Networking",
      children: (
        <div>
          Connect with C-level peers and industry leaders in an exclusive,
          closed-door environment. Enjoy interactive sessions, hands-on demos,
          and peer-to-peer conversations designed to foster deep connections and
          valuable insights. Conclude the day with a lively reception and dinner
          to continue the conversations. As Phoenix’s only summit for data & AI
          leaders, this is a unique opportunity you won’t want to miss.
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="about-area-three ptb-120  pb-0 bg-image">
        <div className="container">
          <div className="row h-100">
            <div className="col-lg-6">
              <div className="about-image">
                <Image
                  src={aboutUsImg}
                  className="about-img"
                  width={"100%"}
                  height={"100%"}
                  alt="about"
                  preview={false}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-content">
                <span>About the Event</span>
                <h2>
                  Join us at this exclusive,
                  <br />
                  invite-only event for C-level data,
                  <br />
                  AI, and IT leaders in the Phoenix area.
                  <br />
                </h2>

                <Tabs
                  activeKey={selectedTab}
                  onChange={handleTabChange}
                  tabPosition="top"
                  className="about-tab"
                  items={tabItems}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutContent;
