import React from "react";
import "./automation.scss";
import { Row, Col } from "antd";
import OrangeBar from "../../Components/OrangeBar/OrangeBar";
import SuccessIndex from "../../Components/Success/SuccessIndex";
import ArrowRight from "../../Components/ArrowRight/ArrowRight";
import AutoCard1 from "../../assets/images/auto/auto_card1.webp";
import AutoCard2 from "../../assets/images/auto/auto_card2.webp";
import AutoCard3 from "../../assets/images/auto/auto_card3.webp";
import AutoCard4 from "../../assets/images/auto/auto_card4.webp";
import AutoCard5 from "../../assets/images/auto/auto_card5.webp";
import AutoCard6 from "../../assets/images/auto/auto_card6.webp";
import AutoPartner1 from "../../assets/images/auto/auto_partner1.webp";
import AutoPartner2 from "../../assets/images/auto/auto_partner2.webp";
import AutoPartner3 from "../../assets/images/auto/auto_partner3.webp";
import AutoPartner4 from "../../assets/images/auto/auto_partner4.webp";
import AutoPartner5 from "../../assets/images/auto/auto_partner5.webp";
import AutoSC1 from "../../assets/images/auto/auto_slant1.webp";
import AutoSC2 from "../../assets/images/auto/auto_slant2.webp";
import AutoSC3 from "../../assets/images/auto/auto_slant3.webp";
import AutoSC4 from "../../assets/images/auto/auto_slant4.webp";
import AutoSC5 from "../../assets/images/auto/auto_slant5.webp";
import AutoBanner from "../../assets/images/services_banners/services_auto.webp";
import AutoLeftText from "../../Components/AutoLeftText/AutoLeftText";
import Partners from "../../Components/Partners/Partners";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import { HeroIndustries } from "../../Components/HeroIndustries";
import { GradientCard } from "../../Components/GradientCard";
import { AutoTable } from "../../Components/AutoTable";

const Automation = (props: any) => {
  FunctionTitles("NucleusTeq | Automation");
  const partnerImages = [
    AutoPartner1,
    AutoPartner2,
    AutoPartner3,
    AutoPartner4,
    AutoPartner5,
  ];

  const autoData = [
    {
      image: AutoSC1,
      heading: "Automation Value Drivers",
      lgValue: 7,
      text: "",
    },
    {
      image: AutoSC2,
      heading: "Minimal Upfront Investment",
      lgValue: 7,
      text: "",
    },
    {
      image: AutoSC3,
      heading: "No Disruption to Underlying System",
      lgValue: 7,
      text: "",
    },
    {
      image: AutoSC4,
      heading: "Lead by Business, Supported by IT",
      lgValue: 7,
      text: "",
    },
    {
      image: AutoSC5,
      heading: "Highly Scalable, Adapts to Changing Business Environment",
      lgValue: 7,
      text: "",
    },
  ];

  const cardDataSets = [
    {
      image: AutoCard1,
      heading: "Front Office",
      lgValue: 7,
      text: "Our robotic process automation offers the highest level of customer experience by providing the fastest, error-free response 24/7.",
    },
    {
      image: AutoCard2,
      heading: "Back Office",
      lgValue: 7,
      text: "Robotic process automation can automate all your back office operations to offer reduced processing cycle times and improved visibility into the status of front office requests and customer standardization.",
    },
    {
      image: AutoCard3,
      heading: "UI Automation",
      lgValue: 7,
      text: "Automation of repeatable processes to provide significant efficiency and avoid manual errors.",
    },
    {
      image: AutoCard4,
      heading: "Reporting Automation",
      lgValue: 7,
      text: "Integration of various systems, and processes and providing integrated reports for actions.",
    },
    {
      image: AutoCard5,
      heading: "ML & AI Driven Bots",
      lgValue: 7,
      text: "With the integration of cognitive technologies such as AI and ML, our NucleusTeq automated bots handle complex transactions that are generally reliant on human expertise and intervention.",
    },
    {
      image: AutoCard6,
      heading: "Chat Bots",
      lgValue: 7,
      text: "Our robotic automation process offers the highest level of customer experience by providing fast, error-free responses 24/7.",
    },
  ];

  return (
    <>
      <div className="automationMain">
        <HeroIndustries
          image={AutoBanner}
          alt={"edmBanner"}
          pageName={"SERVICES  /  ENTERPRISE AUTOMATION"}
          pageIntro={"ATTENDED BOTS"}
          pageIntroPara={
            "Elevate Efficiency with Enterprise Automation and RPA"
          }
        />
        <Row className="automationCenter">
          <Col md={21} xs={22} sm={22} className="uiCenter cloudIntro">
            <p className="paragraphText">
            In the digital age, speed, precision, and efficiency are crucial for any enterprise.
             Robotic Process Automation (RPA) empowers organizations to create virtual "robots" 
             that can emulate and seamlessly integrate human actions within digital systems.
            </p>
          </Col>
          <Col md={21} xs={22} sm={22} className="uiCenter">
            <p className="paragraphText">
            Imagine having tireless digital colleagues who interpret data, trigger responses, 
            and communicate effortlessly with other systems, all while executing a wide range 
            of repetitive tasks—only much more efficiently. RPA robots work tirelessly without rest, 
            and they make zero mistakes, ensuring that your operations run smoother than ever before.
            </p>
          </Col>
          <h3 className="commonTitleHead">KEY BENEFITS</h3>
        </Row>
        <GradientCard data={autoData} />
        {/* <Row className="automationCenter">
          <h3 className="commonTitleHead">AUTOMATION OPPORTUNITIES</h3>
        </Row>
        <ArrowRight /> */}
        <Row className="automationCenter">
          <h3 className="commonTitleHead">
            NUCLEUSTEQ AUTOMATION CAPABILITIES
          </h3>
        </Row>

        <GradientCard data={cardDataSets} />

        <Row className="autoMarginTop">
          <AutoLeftText />
        </Row>

        <Row className="automationCenter">
          <h3 className="commonTitleHead">
            INDUSTRY PROCESSES RELEVANT TO RPA
          </h3>
        </Row>
        <Row className="automationCenter">
          <AutoTable />
        </Row>
        <OrangeBar />
        <Row className="automationCenter autoPartners">
          <Partners
            partnerTitle="OUR AUTOMATION PARTNERS"
            partnerImages={partnerImages}
          />
        </Row>
        <SuccessIndex />
      </div>
    </>
  );
};

export default Automation;
