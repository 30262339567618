import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Space, Carousel } from "antd";
import {
  LeftCircleFilled,
  RightCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import "./sucessStoriesScroll.scss";

interface Props {
  sucessStories: {
    src: string;
    info: string;
    route: string;
  }[];
  onCardClick?:(params:any) => void;
}
const SucessStoriesScroll = ({ sucessStories, onCardClick }: Props) => {
  const navigate = useNavigate();
  const carouselRef1 = useRef<any>(null);
  const carouselRef2 = useRef<any>(null);
  const carouselRef3 = useRef<any>(null);
  const carouselRef4 = useRef<any>(null);
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <Row>
        <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
          <div>
            <Carousel
              slidesToShow={5}
              ref={carouselRef1}
              className="sucessStoriesScroll"
              dots={false}
              autoplay
            >
              {sucessStories.map(({ src, info, route }) => {
                // console.log("src" + src);
                
                return(<div className="sucessStoriesScroll_div" key={route}>
                  <div className="sucessStoriesScroll_div" >
                    <img
                      src={src}
                      alt="nucleusteq"
                      className="sucessStoriesScroll_img"
                      loading="lazy"
                     
                    />
                    <div
                      className="sucessStoriesScroll_overlay"
                      onClick={() => {
                        navigate(route);
                        onCardClick?.(route.replace("/overview?type=",""));
                        handleLinkClick();
                      }}
                    >
                      <PlusCircleOutlined />
                    </div>
                  </div>
                  <div>
                    <p className="sucessStoriesScroll_text">{info}</p>
                  </div>
                </div>)
})}
            </Carousel>
          </div>
        </Col>
        <Col xs={0} sm={0} md={24} lg={0} xl={0} xxl={0}>
          <div>
            <Carousel
              slidesToShow={3}
              ref={carouselRef2}
              className="sucessStoriesScroll"
              dots={false}
            >
              {sucessStories.map(({ src, info, route }) => (
                <div className="sucessStoriesScroll_div" key={route}>
                  <div className="sucessStoriesScroll_div">
                    <img
                      src={src}
                      alt="nucleusteq"
                      className="sucessStoriesScroll_img"
                      loading="lazy"
                    />
                    <div
                      className="sucessStoriesScroll_overlay"
                      onClick={() => {
                        navigate(route);
                        onCardClick?.(route.replace("/overview?type=",""))
                      }}
                    >
                      <PlusCircleOutlined />
                    </div>
                  </div>
                  <div>
                    <p className="sucessStoriesScroll_text">{info}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </Col>
        <Col xs={0} sm={24} md={0} lg={0} xl={0} xxl={0}>
          <div>
            <Carousel
              slidesToShow={2}
              ref={carouselRef3}
              className="sucessStoriesScroll"
              dots={false}
            >
              {sucessStories.map(({ src, info, route }) => (
                <div className="sucessStoriesScroll_div" key={route}>
                  <div className="">
                    <img
                      src={src}
                      alt="nucleusteq"
                      className="sucessStoriesScroll_img"
                      loading="lazy"
                    />
                    <div
                      className="sucessStoriesScroll_overlay"
                      onClick={() => {
                        navigate(route);
                        onCardClick?.(route.replace("/overview?type=",""))
                      }}
                    >
                      <PlusCircleOutlined />
                    </div>
                  </div>
                  <div>
                    <p className="sucessStoriesScroll_text">{info}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </Col>
        <Col
          xs={24}
          sm={0}
          md={0}
          lg={0}
          xl={0}
          xxl={0}
          style={{ justifyContent: "center" }}
        >
          <div>
            <Carousel
              slidesToShow={1}
              ref={carouselRef4}
              className="sucessStoriesScroll"
              dots={false}
            >
              {sucessStories.map(({ src, info, route }) => (
                <div className="sucessStoriesScroll_div" key={route}>
                  <div className="">
                    <img
                      src={src}
                      alt="nucleusteq"
                      className="sucessStoriesScroll_img"
                      loading="lazy"
                    />
                    <div
                      className="sucessStoriesScroll_overlay"
                      onClick={() => {
                        navigate(route);
                        onCardClick?.(route.replace("/overview?type=",""))
                      }}
                    >
                      <PlusCircleOutlined />
                    </div>
                  </div>
                  <div>
                    <p className="sucessStoriesScroll_text">{info}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </Col>
      </Row>
      <div className="sucessStoriesScroll_scroll">
        <Space>
          <LeftCircleFilled
            className="sucessStoriesScroll_scrollleft"
            onClick={() => {
              carouselRef1?.current?.prev();
              carouselRef2?.current?.prev();
              carouselRef3?.current?.prev();
              carouselRef4?.current?.prev();
            }}
          />
          <RightCircleFilled
            className="sucessStoriesScroll_scrollleft"
            onClick={() => {
              carouselRef1?.current?.next();
              carouselRef2?.current?.next();
              carouselRef3?.current?.next();
              carouselRef4?.current?.next();
            }}
          />
        </Space>
      </div>
    </div>
  );
};
export default SucessStoriesScroll;
