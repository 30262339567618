import React from "react";
import { Layout, Col, Row } from "antd";
import { imagesP } from "../../mocks/peoplesImage";
import ImageOfPeople from "../ImageOfPeople/ImageOfPeople";

const LifeCard = () => {
  return (
    <Layout className="cardRows mx-5 my-9 px-5 py-5" style={{background:"transparent"}}>
      <Row gutter={[16, 16]} >
        {imagesP.map(({ img }, index) => {
          return (
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              key={index}
            >
              <ImageOfPeople img={img} />
            </Col>
          );
        })}
      </Row>
    </Layout>
  );
};

export default LifeCard;
